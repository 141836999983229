import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import errorHandling from '../../components/helper-components/alert-component.component';
import {
  SET_START_DATE_ERROR,
  SET_START_DATE_PENDING,
  SET_START_DATE_SUCCESS,
  SET_DEFERRED_STATUS,
} from '../reducers/start-date/start-date.constants';
import IError from '../../models/Error';
import { SET_LEARNER_PROGRAM_ENROLLMENT_START_DATE } from '../reducers/learner-profile/learner-profile.constants';
import { ISelectedStartDate } from '../../components/learner-info/start-date/start-date-modal/start-date-modal.model';

export interface IStartDateObject {
  startDate: Date;
  enrollmentDate: Date;
  maxDeferralMonths: number;
  daysBeforeNextPeriod: number;
  disableStartDateAfterXDays: number;
}


export interface IUpdatedStartDatePayloadObject{
  programEnrollmentId: string;
  startDate: string;
  comment: string | undefined;
}

export const setDeferredStatusAction = (
  deferred: boolean,
): AnyAction => action(SET_DEFERRED_STATUS, deferred);
export const setStartDatePending = (): AnyAction => action(SET_START_DATE_PENDING);
export const setStartDateSuccess = (
  selectedStartDate: IStartDateObject,
): AnyAction => action(SET_START_DATE_SUCCESS, selectedStartDate);
export const setStartDateFailure = (
  error: IError,
): AnyAction => action(SET_START_DATE_ERROR, error);
export const setProgramEnrollmentStartDate = (
  startDatePayload: IUpdatedStartDatePayloadObject,
): AnyAction => action(SET_LEARNER_PROGRAM_ENROLLMENT_START_DATE, startDatePayload);

export const setStartDate = (
  getBearerToken: () => Promise<string>,
  learnerId: string,
  programEnrollmentId: string,
  selectedStartDate: ISelectedStartDate,
) => async (dispatch: Dispatch) => {
  dispatch(setStartDatePending());

  try {
    const token = await getBearerToken();
    const response = await fetch(`/api/program/learners/${learnerId}/program-enrollments/${programEnrollmentId}/start-date`, {
      method: 'PUT',
      headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
      body: JSON.stringify(selectedStartDate),
    });
    if (!response.ok) throw await response;
    const updatedStartDate: IUpdatedStartDatePayloadObject = {
      comment: selectedStartDate.comment,
      programEnrollmentId,
      startDate: selectedStartDate.startDate,
    };
    dispatch(setProgramEnrollmentStartDate(updatedStartDate));
  } catch (error: any) {
    dispatch(setStartDateFailure(error));
    errorHandling({ error });
  }
};
