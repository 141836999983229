import React from 'react';
import cn from 'classnames';
import styles from './helper-text.module.scss';

type Props = {
  variant?: 'default' | 'error' | 'warning' | 'success';
} & React.HTMLAttributes<HTMLDivElement>;

export const HelperText = ({
  variant = 'default',
  children,
  className,
  ...props
}: Props) => (
  <div className={cn(styles.text, styles[variant], className)} {...props}>
    {children}
  </div>
);
