import React from 'react';
import { useParams } from 'react-router-dom';
import LearnerDashboardContent from './learner-dashboard-content';
import { useAuthContext } from '../auth/auth-context';
import { LearnerContainer } from '../learner-container/learner-container';

export const LearnerDashboard = () => {
  const { learnerId } = useParams<{ learnerId: string }>();
  const { getBearerToken } = useAuthContext();

  return (
    <LearnerContainer>
      <LearnerDashboardContent
        learnerId={learnerId}
        getBearerToken={getBearerToken}
      />
    </LearnerContainer>
  );
};
