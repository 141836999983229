import {
  Row,
} from 'react-bootstrap';
import styled from 'styled-components';

export const CenteredRow = styled(Row)`
    display: flex;
    align-items: center;
    width: 100%;
    h4 {
      font-size: 1.25rem;
    }
    h5 {
       font-size: 17px; 
    }
  `;

export const CourseStatusSuccess = styled.h5`
font-weight: bold;
margin-right: 12px;
color: seagreen;
min-width: 77px;
margin-bottom: 0;
display: inline-block;
`;

export const CourseStatusNormal = styled.h5`
color: grey;
margin-bottom: 0;
display: inline-block;
`;

export const CourseStatusFailed = styled.h5`
font-weight: bold;
margin-right: 12px;
color: red;
min-width: 77px;
margin-bottom: 0;
display: inline-block;
`;

export const PassedIcon = styled.img`
content: url("/icons/tick.svg");
height: 22px;
width: 22px;
`;
export const FailedIcon = styled.img`
content: url("/icons/failed.svg");
height: 22px;
width: 22px;
`;

export const GPACalcIcon = styled.i`
  background: url("/icons/gpa-calculator.svg") center center no-repeat;
  background-size: contain;
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  display: inline-block;
  margin: 0 0 0 12px;
`;

export const TransferredIcon = styled.i`
  background: url("/icons/exchange.svg") center center no-repeat;
  background-size: contain;
  display: inline-block;
  height: 20px;
  margin: 0 0 0 12px;
  position: relative;
  top: 2px;
  width: 20px;
`;

export const LoadingP = styled.p`
  padding: 30px 0;
  text-align: center;
  font-size: 13px;
`;
