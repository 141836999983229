import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import IError from '../../models/Error';
import errorHandling from '../../components/helper-components/alert-component.component';
import { ILoaDataObject } from '../../components/learner-info/loa-scheduler/loa-scheduler-modal/loa-scheduler-model.model';
import api from '../api/adminUI.api';
import {
  GET_LOA_SCHEDULER_DATA_PENDING,
  GET_LOA_SCHEDULER_DATA_ERROR,
  GET_LOA_SCHEDULER_DATA_SUCCESS,
  SET_LOA_SCHEDULER_DATA_PENDING,
  SET_LOA_SCHEDULER_DATA_ERROR,
  SET_LOA_SCHEDULER_DATA_SUCCESS,
  GET_SELECT_OPTIONS_PENDING,
  GET_SELECT_OPTIONS_SUCCESS,
  GET_SELECT_OPTIONS_ERROR,
} from '../reducers/loa-scheduler/loa-scheduler.constants';
import { ILoaOptionObject } from '../reducers/loa-scheduler/loa-scheduler.modal';

export const getLoaStatusDataPending = (): AnyAction => action(GET_LOA_SCHEDULER_DATA_PENDING);
export const getLoaStatusDataSuccess = (
  loaSchedulerData: ILoaDataObject,
): AnyAction => action(GET_LOA_SCHEDULER_DATA_SUCCESS, loaSchedulerData);
export const getLoaStatusDataFailure = (
  error: IError,
): AnyAction => action(GET_LOA_SCHEDULER_DATA_ERROR, error);

export const getLoaStatusData = (
  getBearerToken: () => Promise<string>,
  learnerId: string,
  programEnrollmentId: string,
) => async (dispatch: Dispatch) => {
  dispatch(getLoaStatusDataPending());
  try {
    const response = await api.loaScheduler.getLoaStatus(
      learnerId,
      programEnrollmentId,
      getBearerToken,
    );
    if (!response.ok) throw await response;
    const loaSchedulerData = (await response.json()) as ILoaDataObject;
    dispatch(getLoaStatusDataSuccess(loaSchedulerData));
  } catch (error: any) {
    dispatch(getLoaStatusDataFailure(error));
    errorHandling({ error });
  }
};

export const setLoaStatusDataPending = (): AnyAction => action(SET_LOA_SCHEDULER_DATA_PENDING);
export const setLoaStatusDataSuccess = (
  loaSchedulerData: ILoaDataObject,
): AnyAction => action(SET_LOA_SCHEDULER_DATA_SUCCESS, loaSchedulerData);
export const setLoaStatusDataFailure = (
  error: IError,
): AnyAction => action(SET_LOA_SCHEDULER_DATA_ERROR, error);

export const setLoaStatusData = (
  getBearerToken: () => Promise<string>,
  learnerId: string,
  programEnrollmentId: string,
  loaData: ILoaDataObject,
  cancelLoa: boolean,
  scheduleId: null | string,
) => async (dispatch: Dispatch) => {
  dispatch(setLoaStatusDataPending());
  try {
    if (cancelLoa) {
      const response = await api.loaScheduler.cancelLoaStatus(
        learnerId,
        programEnrollmentId,
        getBearerToken,
        scheduleId,
      );
      if (!response.ok) throw await response;
      dispatch(setLoaStatusDataSuccess(loaData));
    } else {
      const response = await api.loaScheduler.updateLoaStatus(
        learnerId,
        programEnrollmentId,
        getBearerToken,
        loaData,
      );
      if (!response.ok) throw await response;
      const loaSchedulerData = (await response.json()) as ILoaDataObject;
      dispatch(setLoaStatusDataSuccess(loaSchedulerData));
    }
  } catch (error: any) {
    dispatch(setLoaStatusDataFailure(error));
    errorHandling({ error });
  }
};

export const getOptionsListPending = (): AnyAction => action(GET_SELECT_OPTIONS_PENDING);
export const getOptionsListListSuccess = (
  optionsObject: ILoaOptionObject,
): AnyAction => action(GET_SELECT_OPTIONS_SUCCESS, optionsObject);
export const getOptionsListFailure = (
  error: IError,
): AnyAction => action(GET_SELECT_OPTIONS_ERROR, error);

export const getOptionsList = (getBearerToken: () => Promise<string>) => async (
  dispatch: Dispatch,
) => {
  dispatch(getOptionsListPending());

  try {
    const response = await api.loaScheduler.getOptionsList(getBearerToken);

    if (!response.ok) throw await response;
    const options = (await response.json()) as ILoaOptionObject;
    dispatch(getOptionsListListSuccess(options));
  } catch (error: any) {
    dispatch(getOptionsListFailure(error));
    errorHandling({ error });
  }
};
