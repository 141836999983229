import { createBlobServiceWithSas } from './azure-blob-upload-service';
import { LEARNER_FILE_TYPE, ADMISSION_FILE_TYPE, APPLICANT_FILE_TYPE } from '../api/adminUI.api';
import { uploadProgressiveFile } from './upload-progressive-file';

interface IUploadSasTokenRequest {
  readonly fileExtension: string;
}

interface IUploadSasTokenResponse {
  readonly sasToken: string;
  readonly accountName: string;
  readonly containerName: string;
  readonly fileName: string;
}

interface ITempFileUploadResponse {
  readonly tempFileId: string;
}

export type UploadFileType = 'aep' | 'government-id' | 'personal-photo' | 'transcript' | 'other-docs' | 'english-certificate' | 'english-individual-evaluation';

export interface FileUploadResult {
  readonly containerName: string;
  readonly fileName: string;
}

export interface IFileUploadService {
  uploadFileByFileType (
    token: string,
    learnerId: string,
    fileType: string,
    type: UploadFileType,
    file: File,
    progressCallback?: (completePercent: number) => void
  ): Promise<FileUploadResult>;

}

class FileUploadService implements IFileUploadService {
  public async uploadFileByFileType(
    token: string,
    learnerId: string,
    fileType: string,
    type: UploadFileType,
    file: File,
    progressCallback?: (completePercent: number) => void,
  ): Promise<FileUploadResult> {
    switch (fileType) {
      case LEARNER_FILE_TYPE:
        return this.uploadLearnerFile(token, learnerId, type, file, progressCallback);
      case ADMISSION_FILE_TYPE:
        return this.uploadAdmissionFile(token, learnerId, type, file, progressCallback);
      case APPLICANT_FILE_TYPE:
        return this.uploadApplicantFile(token, learnerId, type, file, progressCallback);
      default:
        return this.uploadLearnerFile(token, learnerId, type, file, progressCallback);
    }
  }

  public async uploadApplicantFile(
    token: string,
    learnerId: string,
    type: UploadFileType,
    file: File,
    progressCallback?: (completePercent: number) => void,
  ): Promise<FileUploadResult> {
    const applicantionUrl = `/api/admission/applicant-files/${learnerId}/upload-sas-tokens/${type}`;

    return this.uploadFile(token, file, applicantionUrl, progressCallback);
  }

  public async uploadAdmissionFile(
    token: string,
    learnerId: string,
    type: UploadFileType,
    file: File,
    progressCallback?: (completePercent: number) => void,
  ): Promise<FileUploadResult> {
    if (type === 'government-id' || type === 'personal-photo') {
      const result = await this.uploadTempFile(token, learnerId, file, progressCallback);
      return {
        fileName: result.tempFileId,
        containerName: '',
      };
    }
    const admissionUrl = `/api/admission/${learnerId}/upload-sas-tokens/${type}`;
    return this.uploadFile(token, file, admissionUrl, progressCallback);
  }

  public async uploadLearnerFile(
    token: string,
    learnerId: string,
    type: UploadFileType,
    file: File,
    progressCallback?: (completePercent: number) => void,
  ): Promise<FileUploadResult> {
    const learnerFilesUrl = `/api/learner-files/learners/${learnerId}/upload-sas-tokens/${type}`;

    return this.uploadFile(token, file, learnerFilesUrl, progressCallback);
  }

  private async uploadFile(
    token: string,
    file: File,
    url: string,
    progressCallback?: (completePercent: number) => void,
  ): Promise<FileUploadResult> {
    const request: IUploadSasTokenRequest = {
      fileExtension: file.name.substr(file.name.lastIndexOf('.') + 1),
    };

    const response = await fetch(url, {
      method: 'POST',
      headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
      body: JSON.stringify(request),
    });
    if (!response.ok) throw await response.json();
    const data = (await response.json()) as IUploadSasTokenResponse;

    const blobService = createBlobServiceWithSas(data.accountName, data.sasToken);
    if (progressCallback) {
      progressCallback(0);
    }

    await blobService.uploadBlob(data.containerName, data.fileName, file, progressCallback);
    return {
      containerName: data.containerName,
      fileName: data.fileName,
    };
  }

  private async uploadTempFile(
    token: string,
    learnerId: string,
    file: File,
    progressCallback?: (completePercent: number) => void,
  ): Promise<ITempFileUploadResponse> {
    const fileExtension = file.name.substr(file.name.lastIndexOf('.') + 1);

    if (progressCallback) {
      progressCallback(0);
    }
    const response = await uploadProgressiveFile<ITempFileUploadResponse>(`/api/learner-files/learners/${learnerId}/files/temp/${fileExtension}`, file, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.total || progressEvent.loaded;

        if (totalLength !== null && progressCallback) {
          progressCallback(Math.round((progressEvent.loaded * 100) / totalLength));
        }
      },
    });

    return response;
  }
}

const fileUploadService: IFileUploadService = new FileUploadService();
export default fileUploadService;
