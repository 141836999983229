
 
import React from 'react';
import { Row } from 'react-bootstrap';
import { IEvent, IEventDetails, ICustomEventProps } from '../learner-history-model';
import EventProp from './utils/event-prop';

export const enrollmentLearnerMaxSimultaneousCoursesUpdatedEventSelector: (event:IEvent) => IEventDetails = (event:IEvent) => ({
  metadata: {
    type: {
      category: 'Course Enrollment',
      eventName: 'Max Simultaneous Courses',
    },
    date: event.updatedDateTime,
    editor: event.editor,
  },
  element: <Event event={event} />,
});

const Event: React.FunctionComponent<ICustomEventProps> = (props: ICustomEventProps) => {
  const { event } = props;

  return (
    <Row>
      <EventProp name="Max Courses" value={event.maxSimultaneousCourse} primary />
    </Row>
  );
};
