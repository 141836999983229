
 
import React from 'react';
import { Row } from 'react-bootstrap';
import { IEvent, IEventDetails, ICustomEventProps } from '../learner-history-model';
import EventProp from './utils/event-prop';

export const learnerProfileSuccessAdvisorAssignedEventSelector: (event:IEvent) => IEventDetails = (event:IEvent) => ({
  metadata: {
    type: {
      category: 'Learner Profile',
      eventName: 'Success Advisor Assigned',
    },
    date: event.updatedDateTime,
    editor: event.actor,
  },
  element: <Event event={event} />,
});

const Event: React.FunctionComponent<ICustomEventProps> = (props: ICustomEventProps) => {
  const { event } = props;

  const successAdvisor = event.successAdvisor
    ? `${event.successAdvisor.email} ${event.successAdvisor.firstName} ${event.successAdvisor.lastName}`.trim()
    : 'None';

  return (
    <Row>
      <EventProp name="Success Advisor" value={successAdvisor} primary />
    </Row>
  );
};
