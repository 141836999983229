import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { PersonalDetailsCard, StyledWrapper } from '../../styled-components';
import { BoldColumn } from '../../../learner-info/styled-components';

import FieldEditor from '../editing-component/editing-component';
import { IApplicantPersonalInfoEditable, INewFieldValue } from '../applicant-editable-info.model';
import FieldEditorPhone from '../editing-component/editing-component-phone';
import { StyledApplicantEditableInfo } from '../styled-components';
import { UNITED_STATES, UNITED_STATES_ISO } from '../../admissions-form/admissions-form.constants';
import { ISelectOption } from '../../../../models/SelectOptions';

interface IApplicantPersonalDetailsProps {
  personalInfoEditable: IApplicantPersonalInfoEditable | null;
  countriesOptions: ISelectOption[] | null;
  stateOptions: ISelectOption[] | null;
  nationalitiesOptions: ISelectOption[] | null;
  handleApplicantProfileValueChange: (
    newValue: INewFieldValue,
    changedFieldName: string,
  ) => Promise<void>;
}

const ApplicantPersonalDetailsEditable: React.FC<IApplicantPersonalDetailsProps> = (
  props: IApplicantPersonalDetailsProps,
): JSX.Element => {
  const {
    personalInfoEditable,
    handleApplicantProfileValueChange,
    countriesOptions,
    stateOptions,
    nationalitiesOptions,
  } = props;
  const usaUser = personalInfoEditable
    && (personalInfoEditable.Country === UNITED_STATES
      || personalInfoEditable.Country === UNITED_STATES_ISO);

  // @ts-ignore
  return (
    <StyledApplicantEditableInfo>
      <StyledWrapper>
        <h3>Personal Details</h3>
        <PersonalDetailsCard>
          <Row>
            <Col xs={6}>
              <span>First Name:</span>
            </Col>
            <BoldColumn xs={6}>
              <span>
                {
                  personalInfoEditable
                  && (
                    <FieldEditor
                      oldValue={personalInfoEditable.FirstName}
                      propertyName="FirstName"
                      changedFieldUrl="firstname"
                      fieldType="input"
                      maxSymbols={40}
                      handleNewValue={handleApplicantProfileValueChange}
                      applicationStatus={personalInfoEditable.ApplicationStatus}
                    />
                  )
                }
              </span>
            </BoldColumn>
          </Row>
          <Row>
            <Col xs={6}>
              <span>Last Name:</span>
            </Col>
            <BoldColumn xs={6}>
              <span>
                <FieldEditor
                  oldValue={personalInfoEditable && personalInfoEditable.LastName}
                  maxSymbols={80}
                  propertyName="LastName"
                  changedFieldUrl="lastname"
                  fieldType="input"
                  handleNewValue={handleApplicantProfileValueChange}
                  applicationStatus={personalInfoEditable && personalInfoEditable.ApplicationStatus}
                />
              </span>
            </BoldColumn>
          </Row>
          <Row>
            <Col xs={6}>
              <span>Phone:</span>
            </Col>
            <BoldColumn xs={6}>
              <FieldEditorPhone
                oldPhoneCode={personalInfoEditable && personalInfoEditable.PhoneCountryCode}
                oldPhoneNumber={personalInfoEditable && personalInfoEditable.PhoneNumber}
                propertyName="phoneNumber"
                changedFieldUrl="phoneNumber"
                handleNewValue={handleApplicantProfileValueChange}
                applicationStatus={personalInfoEditable && personalInfoEditable.ApplicationStatus}
              />
            </BoldColumn>
          </Row>
          <Row>
            <Col xs={6}>
              <span>Year Of Birth:</span>
            </Col>
            <BoldColumn xs={6}>
              <FieldEditor
                oldValue={personalInfoEditable && personalInfoEditable.YearOfBirth}
                maxSymbols={4}
                propertyName="YearOfBirth"
                changedFieldUrl="yearOfBirth"
                fieldType="number"
                paramsValidation="yearOfBirth"
                handleNewValue={handleApplicantProfileValueChange}
                applicationStatus={personalInfoEditable && personalInfoEditable.ApplicationStatus}
              />
            </BoldColumn>
          </Row>
          <Row>
            <Col xs={6}>
              <span>Gender:</span>
            </Col>
            <BoldColumn xs={6}>
              <FieldEditor
                oldValue={personalInfoEditable && personalInfoEditable.Gender}
                propertyName="Gender"
                changedFieldUrl="gender"
                fieldType="select"
                handleNewValue={handleApplicantProfileValueChange}
                applicationStatus={personalInfoEditable && personalInfoEditable.ApplicationStatus}
              />
            </BoldColumn>
          </Row>
          <Row>
            <Col xs={6}>
              <span>Country:</span>
            </Col>
            <BoldColumn xs={6}>
              <FieldEditor
                selectOptions={countriesOptions}
                oldValue={personalInfoEditable && personalInfoEditable.Country}
                propertyName="Country"
                changedFieldUrl="country"
                fieldType="select"
                handleNewValue={handleApplicantProfileValueChange}
                applicationStatus={personalInfoEditable && personalInfoEditable.ApplicationStatus}
              />
            </BoldColumn>
          </Row>
          <Row>
            <Col xs={6}>
              <span>Nationality:</span>
            </Col>
            <BoldColumn xs={6}>
              <FieldEditor
                oldValue={personalInfoEditable && personalInfoEditable.Nationality}
                propertyName="Nationality"
                changedFieldUrl="nationality"
                fieldType="select"
                selectOptions={nationalitiesOptions}
                handleNewValue={handleApplicantProfileValueChange}
                applicationStatus={personalInfoEditable && personalInfoEditable.ApplicationStatus}
              />
            </BoldColumn>
          </Row>
          <Row>
            {usaUser ? (
              <>
                <Col xs={6}>
                  <span>State:</span>
                </Col>
                <BoldColumn xs={6}>
                  <FieldEditor
                    oldValue={personalInfoEditable && personalInfoEditable.State}
                    selectOptions={stateOptions}
                    propertyName="State"
                    changedFieldUrl="state"
                    fieldType="select"
                    handleNewValue={handleApplicantProfileValueChange}
                    applicationStatus={personalInfoEditable
                      && personalInfoEditable.ApplicationStatus}
                  />
                </BoldColumn>
              </>
            ) : (
              <>
                <Col xs={6}>
                  <span>City:</span>
                </Col>
                <BoldColumn xs={6}>
                  <FieldEditor
                    oldValue={personalInfoEditable && personalInfoEditable.City}
                    maxSymbols={75}
                    propertyName="City"
                    changedFieldUrl="city"
                    fieldType="input"
                    handleNewValue={handleApplicantProfileValueChange}
                    applicationStatus={personalInfoEditable
                      && personalInfoEditable.ApplicationStatus}
                  />
                </BoldColumn>
              </>
            )}
          </Row>
          <Row>
            <Col xs={6}>
              <span>Native Language:</span>
            </Col>
            <BoldColumn xs={6}>
              <FieldEditor
                oldValue={personalInfoEditable && personalInfoEditable.Language}
                propertyName="Language"
                changedFieldUrl="language"
                fieldType="select"
                handleNewValue={handleApplicantProfileValueChange}
                applicationStatus={personalInfoEditable && personalInfoEditable.ApplicationStatus}
              />
            </BoldColumn>
          </Row>
        </PersonalDetailsCard>
      </StyledWrapper>

      <StyledWrapper>
        <h3>Prior Education</h3>
        <PersonalDetailsCard>
          <Row>
            <Col xs={6}>
              <span>Institution Name:</span>
            </Col>
            <BoldColumn xs={6}>
              <FieldEditor
                oldValue={personalInfoEditable && personalInfoEditable.InstitutionName}
                propertyName="InstitutionName"
                changedFieldUrl="institutionName"
                fieldType="input"
                maxSymbols={200}
                handleNewValue={handleApplicantProfileValueChange}
                applicationStatus={personalInfoEditable && personalInfoEditable.ApplicationStatus}
              />
            </BoldColumn>
          </Row>

          <Row>
            <Col xs={6}>
              <span>Institution Language:</span>
            </Col>
            <BoldColumn xs={6}>
              <FieldEditor
                oldValue={personalInfoEditable && personalInfoEditable.InstitutionLanguage}
                propertyName="InstitutionLanguage"
                changedFieldUrl="institutionLanguage"
                fieldType="select"
                handleNewValue={handleApplicantProfileValueChange}
                applicationStatus={personalInfoEditable && personalInfoEditable.ApplicationStatus}
              />
            </BoldColumn>
          </Row>

          <Row>
            <Col xs={6}>
              <span>Graduation Year:</span>
            </Col>
            <BoldColumn xs={6}>
              <FieldEditor
                oldValue={personalInfoEditable && personalInfoEditable.GraduationYear}
                propertyName="GraduationYear"
                changedFieldUrl="graduationYear"
                fieldType="number"
                maxSymbols={4}
                handleNewValue={handleApplicantProfileValueChange}
                applicationStatus={personalInfoEditable && personalInfoEditable.ApplicationStatus}
              />
            </BoldColumn>
          </Row>
          <Row>
            <Col xs={6}>
              <span>Country:</span>
            </Col>
            <BoldColumn xs={6}>
              <FieldEditor
                oldValue={personalInfoEditable && personalInfoEditable.InstitutionCountry}
                propertyName="InstitutionCountry"
                changedFieldUrl="institutionCountry"
                handleNewValue={handleApplicantProfileValueChange}
                fieldType="select"
                selectOptions={countriesOptions}
                applicationStatus={personalInfoEditable && personalInfoEditable.ApplicationStatus}
              />
            </BoldColumn>
          </Row>
          <Row>
            <Col xs={6}>
              <span>Highest education level:</span>
            </Col>
            <BoldColumn xs={6}>
              <FieldEditor
                oldValue={personalInfoEditable && personalInfoEditable.HighestEducationLevel}
                propertyName="HighestEducationLevel"
                changedFieldUrl="highestEducationLevel"
                fieldType="select"
                handleNewValue={handleApplicantProfileValueChange}
                applicationStatus={personalInfoEditable && personalInfoEditable.ApplicationStatus}
              />
            </BoldColumn>
          </Row>
          <Row>
            <Col xs={6}>
              <span>Degree or Certificate Name:</span>
            </Col>
            <BoldColumn xs={6}>
              <FieldEditor
                oldValue={personalInfoEditable && personalInfoEditable.DegreeOrCertificateName}
                propertyName="DegreeOrCertificateName"
                changedFieldUrl="degreeOrCertificateName"
                fieldType="input"
                handleNewValue={handleApplicantProfileValueChange}
                applicationStatus={personalInfoEditable && personalInfoEditable.ApplicationStatus}
              />
            </BoldColumn>
          </Row>
        </PersonalDetailsCard>
      </StyledWrapper>
    </StyledApplicantEditableInfo>
  );
};
export default ApplicantPersonalDetailsEditable;
