import { useQuery } from '@tanstack/react-query';
import { appFetch } from './fetch';
import { useAuthContext } from 'components/auth/auth-context';

export interface IFXRate {
  rateId: string;
  currencyCode: string;
  fxRate: number;
  effectiveFrom: string;
}

export const getFxRates = async (getBearerToken: () => Promise<string>) => {
  const token = await getBearerToken();

  return appFetch<IFXRate[]>('/api/learner-payments-api/fx-rates/current', {
    method: 'GET',
    headers: [['Authorization', `Bearer ${token}`]],
  });
};

export const useFxRates = () => {
  const { getBearerToken } = useAuthContext();

  return useQuery({
    queryKey: ['fx-rates'],
    queryFn: () => getFxRates(getBearerToken),
  });
};
