 
import React from 'react';
import Downshift from 'downshift';
import { Redirect } from 'react-router-dom';
import { DebounceInput } from 'react-debounce-input';
import AuthProps from '../auth/auth-props';
import {
  QuickSearchDiv,
  QuickSearchHeader,
  QuickSearchItemList,
  QuickSearchSpinner,
  AdvancedSearchLink,
} from './styled-components';

interface SearchState {
  status: 'Loading' | 'Errored' | 'Loaded';
  searchValue?: string;
  results?: any;
  selection: any;
  newRoute: boolean;
}

interface LearnerResult {
  readonly Id: string;
  readonly Email: string;
  readonly FirstName: string;
  readonly LastName: number;
}

export default class QuickSearch extends React.PureComponent<AuthProps, SearchState> {
  constructor(props: AuthProps) {
    super(props);

    this.state = {
      status: 'Loaded',
      searchValue: '',
      results: [],
      selection: { name: 'set', id: '' },
      newRoute: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.chooseStudent = this.chooseStudent.bind(this);
  }

  handleChange = async (event: any): Promise<void> => {
    const searchString = event.target.value;

    if (searchString.length < 3) {
      this.setState({
        searchValue: searchString,
      });
      return;
    }

    this.setState({
      searchValue: searchString,
      status: 'Loading',
    });

    try {
      const { getBearerToken } = this.props;
      const token = await getBearerToken();
      const response = await fetch(`/api/learner-search/suggest/?$top=25&search=${searchString}&$select=FirstName,LastName,Email,Id`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (response.ok) {
        const results = (await response.json()).value.map((x: LearnerResult) => ({
          name: `${x.FirstName} ${x.LastName}`,
          email: x.Email,
          id: x.Id,
        }));
        this.setState({ results, status: 'Loaded' });
      }
    } catch (error: any) {
      this.setState((state) => ({
        ...state,
        status: 'Errored',
        error: error.message,
      }));
    }
  };

  chooseStudent = (selection: any) => {
    this.setState((state) => ({
      ...state,
      selection,
      newRoute: true,
    }));
  };

  render(): JSX.Element {
    const {
      searchValue,
      results,
      selection,
      newRoute,
      status,
    } = this.state;

    if (newRoute) {
      return (
        <Redirect to={{
          pathname: `/learners/${selection.id}`,
          state: { selection },
        }}
        />
      );
    }

    return (
      <QuickSearchDiv>
        <QuickSearchHeader>Quick Search</QuickSearchHeader>
        <Downshift<{name: string}>
          onChange={this.chooseStudent}
          itemToString={(item) => item ? item.name : ''}
          isOpen={!(typeof searchValue === 'undefined' || searchValue.length < 3)}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            isOpen,
            inputValue,
            highlightedIndex,
            selectedItem,
          }) => (
            <div>
              {status === 'Loading' && <QuickSearchSpinner animation="border" size="sm" />}
              <DebounceInput
                debounceTimeout={300}
                {...getInputProps() as any}
                onChange={this.handleChange}
              />
              <AdvancedSearchLink to="/learnersearch">Advanced</AdvancedSearchLink>
              <br />
              <QuickSearchItemList {...getMenuProps()}>
                {isOpen
                  ? results
                    .filter((result: any) => !inputValue || result.name.includes(inputValue))
                    .map((item: any, index: number) => (
                      <li {...getItemProps({
                        key: item.id,
                        index,
                        item,
                        style: {
                          backgroundColor: highlightedIndex === index ? 'lightgrey' : 'white',
                          fontWeight: selectedItem === item ? 'bold' : 'normal',
                        },
                      })}
                      key={item.id}
                      >
                        {item.id}
                        <strong>
                          {' '}
                          {item.name}
                          {' '}
                        </strong>
                        {` ${item.email}`}
                      </li>
                    ))
                  : null}
              </QuickSearchItemList>
            </div>
          )}
        </Downshift>
      </QuickSearchDiv>
    );
  }
}
