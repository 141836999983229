import React, { useMemo } from 'react';
import { Tab } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { usePartnerDetailsParams } from '../../hooks/use-partner-details-params';
import api from '../../../../../shared/api/adminUI.api';
import { useAuthContext } from '../../../../auth/auth-context';
import { Tabs } from '../../../../ui/tabs/tabs';
import { Table } from 'components/ui/table/table';
import { createExpandableData } from 'components/ui/table';
import { Link } from 'react-router-dom';

const StatusCell = ({ status }: { status: string }) => {
  let color = 'black';
  if (status === 'Enrolled' || status === 'Graduated') {
    color = 'green';
  } else if (status === 'Dismissed') {
    color = 'gray';
  } else if (status === 'Suspended') {
    color = 'red';
  }

  return <span style={{ color }}>{status}</span>;
};

export const PartnerLearnersTable = () => {
  const { partnerId } = usePartnerDetailsParams();
  const { getBearerToken } = useAuthContext();
  const { data: enrollments, isLoading } = useQuery({
    queryKey: ['partners', partnerId, 'learners'],
    queryFn: () => api.partners.programEnrollments(partnerId, getBearerToken),
  });

  const grouppedEnrollments = useMemo(
    // TODO - decide if we want to move this util inside table itself
    () => createExpandableData(enrollments ?? [], 'learnerId'),
    [enrollments]
  );

  return (
    <div>
      <Tabs defaultActiveKey='learners' id='learnerInfoTabs'>
        <Tab eventKey='learners' title='Learners'>
          <Table
            emptyText='No learners found for this partner'
            data={grouppedEnrollments}
            loading={isLoading}
            columns={[
              {
                accessorKey: 'learnerId',
                header: 'Learner Id',
                cell: ({ cell }) => (
                  <Link to={`/learners/${cell.getValue()}`}>
                    {cell.getValue()}
                  </Link>
                ),
              },
              { accessorKey: 'productType', header: 'Program' },
              {
                accessorKey: 'programStatusName',
                header: 'Status',
                cell: ({ row }) => (
                  <StatusCell status={row.original.programStatusName} />
                ),
              },
            ]}
            expandable
          />
        </Tab>
      </Tabs>
    </div>
  );
};
