/* eslint react/prop-types: 0 */

import React, { useState } from 'react';

import { Button, Modal } from 'react-bootstrap';
import {
  AdmittanceResponse, educationInfoKeys, personalDetailsKeys,
} from '../../../models/LearnerRegistration';
import { useNationalityOptionsContext } from '../../../shared/providers/nationalityOptionsProvider';
import { LearnerRegistrationApplicantBlock } from '../styled.components';

export interface AdmittanceStatusModalProps {
  admittanceStatus: AdmittanceResponse;
}

const AdmittanceStatusModal = (props: AdmittanceStatusModalProps) => {
  const { admittanceStatus } = props;
  const {
    productCode, productType, decisionDate, decisionMadeBy, admittanceLearnerData,
  } = admittanceStatus;
  const {
    countryOptions, stateOptions, nationalityOptions, loading: nationalityOptionsLoading,
  } = useNationalityOptionsContext();

  const readableCountryName = (countryCode: string) => {
    if (!countryOptions) return countryCode;
    return countryOptions?.find((c) => (c.value === countryCode))?.label || countryCode;
  };

  const readableStateName = (stateCode: string) => {
    if (!stateOptions) return stateCode;
    return stateOptions?.find((s) => (s.value === stateCode))?.label || stateCode;
  };

  const readableNationalityName = (nationalityCode: string) => {
    if (!nationalityOptions) return nationalityCode;
    return nationalityOptions?.find((n) => (n.value === nationalityCode))?.label || nationalityCode;
  };

  const [showModal, setShowModal] = useState(false);

  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const ModalPanel = () => (
    <Modal size="lg" show={showModal} onHide={handleClose} backdrop="static" animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{productCode} {productType}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LearnerRegistrationApplicantBlock>
          <h3>Registration</h3>
          <ul>
            <li>
              <span>Product</span> {productCode} {productType}
            </li>
            <li>
              <span>Reviewed by</span> {decisionMadeBy}
            </li>
            <li>
              <span>Decision Date</span> {decisionDate && new Date(decisionDate).toLocaleString()}
            </li>
          </ul>
          <h3>Personal Details</h3>
          <ul>
            {Object.entries(personalDetailsKeys).map(([key, value]) => {
              if (key === 'PhoneNumber') return <li key={`per-${key}`}><span>{value}:</span>{admittanceLearnerData?.PhoneCountryCode} {admittanceLearnerData?.PhoneNumber}</li>;
              if (key === 'Country') return <li key={`per-${key}`}><span>{value}:</span>{readableCountryName(admittanceLearnerData?.[key] || '')}</li>;
              if (key === 'State') {
                if (admittanceLearnerData?.Country !== 'US') return null;
                return (
                  <li key={`per-${key}`}>
                    <span>{value}:</span>{readableStateName(admittanceLearnerData?.[key] || '')}
                  </li>
                );
              }
              if (key === 'Nationality') return <li key={`per-${key}`}><span>{value}:</span>{readableNationalityName(admittanceLearnerData?.[key] || '')}</li>;
              return <li key={`per-${key}`}><span>{value}:</span>{admittanceLearnerData?.[key]}</li>;
            })}
          </ul>
          <h3>Previous Education</h3>
          <ul>
            {Object.entries(educationInfoKeys).map(([key, value]) => {
              if (key === 'Accredited' || key === 'IsBusinessRelated') {
                return (
                  <li key={`edu-${key}`}>
                    <span>{value}:</span>
                    {admittanceLearnerData?.[key] === true && 'Yes'}
                    {admittanceLearnerData?.[key] === false && 'No'}
                  </li>
                );
              }
              return <li key={`edu-${key}`}><span>{value}:</span>{admittanceLearnerData?.[key]}</li>;
            })}

          </ul>
        </LearnerRegistrationApplicantBlock>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div>
      <Button variant="secondary" onClick={handleOpen}>
        Open Details Popup
      </Button>
      <ModalPanel />
    </div>
  );
};

export default AdmittanceStatusModal;
