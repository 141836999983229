// @ts-ignore
import React, { useState } from 'react';
import { Button, Row, Col, Form, Spinner, InputGroup } from 'react-bootstrap';
import { FiSave, FiEdit } from 'react-icons/fi';
import { GiCancel } from 'react-icons/gi';
import PhoneInput from 'react-phone-input-2';

import {
  EditButton,
} from '../../editing-component/styled-component';
import { INewFieldValue } from '../../admissions.model';
import 'react-phone-input-2/lib/style.css';
import { APPLICANT_STATUS } from '../../../constants/common-constants';

interface IProps {
  oldPhoneCode: string | any;
  oldPhoneNumber: string | any;
  propertyName: string;
  changedFieldUrl: string;
  handleNewValue: (
    newValue: INewFieldValue,
    changedFieldUrl: string
  ) => Promise<void>;
  applicationStatus: string | any;
}

const FieldEditorPhone: React.FunctionComponent<IProps> = ({
  oldPhoneCode,
  oldPhoneNumber,
  propertyName,
  changedFieldUrl,
  handleNewValue,
  applicationStatus,
}: IProps) => {
  const fullPhoneValue = `${oldPhoneCode}${oldPhoneNumber}`;
  const [editing, setEditing] = useState(false);
  const [fieldValueCode, setNewFieldValueCode] = useState(oldPhoneCode);
  const [fieldValueNumber, setNewFieldValueNumber] = useState(oldPhoneNumber);
  const [validationError, setFormError] = useState<null | string>(null);

  // @ts-ignore
  const edit = () => {
    setEditing(true);
    setNewFieldValueCode(fieldValueCode);
    setNewFieldValueNumber(fieldValueNumber);
  };

  const cancel = () => {
    setEditing(false);
    setNewFieldValueCode(fieldValueCode);
    setNewFieldValueNumber(fieldValueNumber);
    setFormError(null);
  };

  const save = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    // @ts-ignore
    const payload = {
      PhoneCountryCode: fieldValueCode,
      PhoneNumber: fieldValueNumber,
    };
    await handleNewValue(payload, changedFieldUrl);
    setEditing(false);
  };

  const onPhoneChange = (newValue: any, { dialCode }: any) => {
    setNewFieldValueCode(dialCode);
    setNewFieldValueNumber(newValue.slice(dialCode.length));
  };

  if (editing) {
    return (
      <>
        <Form onSubmit={save}>
          <InputGroup>
            <div className='flex-grow-1'>
              {!oldPhoneCode || !oldPhoneNumber ? (
                <Spinner animation='border' size='sm' />
              ) : (
                <PhoneInput
                  value={`${oldPhoneCode}${oldPhoneNumber}`}
                  onChange={onPhoneChange}
                  countryCodeEditable={false}
                  disableCountryCode={false}
                />
              )}
            </div>

            <Button
              variant='outline-secondary'
              size='sm'
              disabled={!!validationError}
              type='submit'
            >
              <FiSave />
            </Button>
            <Button variant='outline-secondary' size='sm' onClick={cancel}>
              <GiCancel />
            </Button>
          </InputGroup>
        </Form>
      </>
    );
  }

  return (
    <Row>
      <Col id={propertyName}>
        <span>{fullPhoneValue}</span>
      </Col>
      <div className='w-auto'>
        {((applicationStatus &&
          applicationStatus === APPLICANT_STATUS.ENROLLING) ||
          (applicationStatus &&
            applicationStatus === APPLICANT_STATUS.SUBMITTED) ||
          (applicationStatus &&
            applicationStatus === APPLICANT_STATUS.REGISTRATION)) && (
          <EditButton variant='outline-secondary' size='sm' onClick={edit}>
            <FiEdit />
          </EditButton>
        )}
      </div>
    </Row>
  );
};

export default FieldEditorPhone;
