import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './partner-details-header.module.scss';
import { usePartner } from '../../../hooks/use-partner';
import { usePartnerDetailsParams } from '../../hooks/use-partner-details-params';

export const PartnerDetailsHeader = () => {
  const { partnerId } = usePartnerDetailsParams();
  const { data } = usePartner(partnerId);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h4>{data?.accountName}</h4>
      </div>
      <div className={styles.actions}>
        <Button as={Link as any} to={`/partners/${partnerId}/codes`}>
          Codes
        </Button>
        <Button as={Link as any} to={`/partners/${partnerId}/agreements`}>
          Agreements
        </Button>
        <Button onClick={console.log}>Payment Account</Button>
      </div>
    </div>
  );
};
