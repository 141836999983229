import React, { useState } from 'react';
import api from '../../../shared/api/adminUI.api';
import errorHandling from '../../helper-components/alert-component.component';
import TextFieldEditor from '../editing-component/textfield-editor';


export interface IOfficialNameProps {
  readonly learnerId: string;
  readonly currentValue: string;
  readonly canEditOfficialName: boolean;
  readonly getBearerToken: () => Promise<string>;
}

const OfficialName: React.FunctionComponent<IOfficialNameProps> = (
  props: IOfficialNameProps,
) => {
  const {
    canEditOfficialName, learnerId, getBearerToken, currentValue,
  } = props;
  const [officialName, setOfficialName] = useState(currentValue);

  const handleFieldValueChange = async (newValue: string): Promise<void> => {
    try {
      const response = await api.learnerIdentity.updateOfficialName(
        learnerId,
        getBearerToken,
        newValue,
      );
      if (!response.ok) throw await response;
      setOfficialName(newValue);
    } catch (error: any) {
      errorHandling({ error });
    }
  };

  return (
    <>
      <TextFieldEditor
        oldValue={officialName}
        propertyName="officialName"
        canEdit={canEditOfficialName}
        handleNewValue={handleFieldValueChange}
        fieldType="input"
      />
    </>
  );
};
export default OfficialName;
