
 
import React from 'react';
import { IEvent, IEventDetails } from '../learner-history-model';
import NopEvent from './nop-event';

export const billingTuitionPaymentsResumedEventSelector: (event:IEvent) => IEventDetails = (event:IEvent) => ({
  metadata: {
    type: {
      category: 'System Access',
      eventName: 'Tuition Payments Resumed',
    },
    date: event.updatedDateTime,
  },
  element: <NopEvent event={event} />,
});
