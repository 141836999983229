import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  Button,
  ButtonGroup,
  Col,
  OverlayTrigger,
  Row,
  ToggleButton,
  Tooltip,
} from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { FaTrashAlt } from 'react-icons/fa';
import AuthProps from '../auth/auth-props';
import api from '../../shared/api/adminUI.api';
import {
  AdmittanceResponse,
  AdmittanceStatusEnum,
  EnglishProficiency,
  ProductItem,
  RequirementStatus,
  RequirementType,
} from '../../models/LearnerRegistration';
import IError from '../../models/Error';
import { WithLoading } from '../helper-components/loading.component';
import { WithErrorHandling } from '../helper-components/error-handling.component';

import {
  AdmissionDecisionBlock,
  LearnerRegistrationApplicantBlock,
  LearnerRegistrationHeading,
  LearnerRegistrationStatusBlock,
} from './styled.components';
import IdentityInfo from '../../shared/models/identity-info';
import PersonalDetailsComponent from './personal-details/personal-details';
import PreviousEducationComponent from './previous-education/previous-education';
import VerificationSteps from './verification-steps/verification-steps';
import { COMMENT } from '../constants/common-constants';
import {
  commentRequired,
  composeValidators,
  minLength,
} from '../../utils/validate.utils';
import {
  emptySelectOption,
  Error,
  renderCheckbox,
  renderTextField,
} from '../helper-components/form-components/form-filed-components/form-filed.components';
import {
  ACCEPT,
  ACCEPTANCE_PATH,
  ACCEPTED_EVALUATION_PENDING,
  ADMISSION_DECISION,
  AEP_DOCUMENTS,
  COMMENT_WARNING,
  INCORRECT,
  MISSING,
  OK,
  PARTNER,
  PRODUCT_CODE,
  PRODUCT_CODE_ERROR,
  PRODUCT_TYPE,
  UNITED_STATES_ISO,
  UNREADBLE,
} from '../admissions/admissions-form/admissions-form.constants';
import {
  CountryMessage,
  RadioButtonCol,
  RadioLabel,
  TrashButton,
} from '../admissions/admissions-form/styled-components';
import {
  IProductTypeSelectOption,
  ISelectOption,
} from '../../models/SelectOptions';
import { IFormErrors } from '../admissions/admissions-form/admissions-form.model';
import AdmittanceStatusModal from './admittance-status-modal/admittance-status-modal';
import UndoConfirmationModal from './undo-confirmation-modal/undo-confirmation-modal';
import ApplicantFilesComponent from './applicant-files/applicant-files';
import RegistrationsListComponent from './registrations-list/registrations-list';
import { LongTextColumn } from '../learner-info/styled-components';
import InputableSelect from '../../shared/inputable-select';
import { IAEPDocument } from '../admissions/admissions.model';
import { ILearnerAEPDocument } from '../learner-files/learner-files.model';
import { usePartners } from '../manage-partners/hooks/use-partners';
import PartnerDetailsComponent from './partner-details/partner-details';
import { ProductTypeSelect } from './approve-admission-decision-form/product-type-select';
import { ProductCodeSelect } from './approve-admission-decision-form/product-code-select';
import { useActiveRegistrations } from './hooks/use-active-registrations';
import { formatDate } from '../helper-components/form-components/form-filed-components/form-filed.components';

type RequestStateType = 'initial' | 'loading' | 'success' | 'error';

const LearnerRegistration = (props: AuthProps) => {
  const { getBearerToken } = props;
  const { applicantId } = useParams<{ applicantId: string }>();

  const [registrationIsEditable, setRegistrationIsEditable] = useState(false);
  const {
    data: activeRegistrations,
    error: activeRegistrationError,
    refetch: refetchActiveRegistration,
    status: activeRegistrationState,
  } = useActiveRegistrations({
    onSuccess(registrations) {
      const admissionDecisionIndex = registrations[0].requirements
        .map((e) => e.requirement)
        .indexOf(RequirementType.AdmissionDecision);
      setRegistrationIsEditable(
        registrations[0].requirements[admissionDecisionIndex - 1]?.status ===
          'Fulfilled' &&
          registrations[0].status !== 'Completed' &&
          registrations[0].status !== 'Rejected'
      );
    },
  });

  const [verificationComplete, setVerificationComplete] = useState(false);
  const [selectedRegistrationIndex, setSelectedRegistrationIndex] =
    useState<number>(0);
  const [regLocation, setRegLocation] = useState<{
    country: string | null;
    state: string | null;
  }>();

  const [admittanceStatusState, setAdmittanceStatusState] =
    useState<RequestStateType>('initial');
  const [admittanceStatus, setAdmittanceStatus] =
    useState<AdmittanceResponse>();
  const [admittanceStatusError, setAdmittanceStatusError] =
    useState<IError | null>(null);
  const [admittanceUpdateInProgress, setAdmittanceUpdateInProgress] =
    useState(false);
  const [admissionDecisionChoice, setAdmissionDecisionChoice] = useState<
    'approve' | 'reject' | undefined
  >();
  const [admittanceViewOnly, setAdmittanceViewOnly] = useState(false);

  const [productListState, setProductListState] =
    useState<RequestStateType>('initial');
  const [productList, setProductList] = useState<
    Array<IProductTypeSelectOption>
  >([]);
  const [productListError, setProductListError] = useState<IError | null>(null);

  const [aepListState, setAepListState] = useState<RequestStateType>('initial');
  const [aepOptionsList, setAepOptionsList] = useState<ISelectOption[]>([]);
  const [defaultAepDocuments, setDefaultAepDocuments] = useState<
    Array<IAEPDocument>
  >([]);

  const [initialProductTypeValue, setInitialProductTypeValue] = useState<
    IProductTypeSelectOption | undefined
  >();
  const [initialProductCodeValue, setInitialProductCodeValue] = useState<
    ISelectOption | undefined
  >();
  const [initialPartner, setInitialPartner] = useState<
    ISelectOption | undefined
  >();
  const [changedProductOnAccept, setChangedProductOnAccept] =
    useState<string>();

  const { data: partners } = usePartners();

  const partnerList = useMemo<Array<ISelectOption>>(
    () =>
      partners?.map((partner) => ({
        label: partner.accountName,
        value: partner.partnerId,
      })) || [],
    [partners]
  );

  const [hasEngProficiencyReq, setHasEngProficiencyReq] = useState<
    boolean | undefined
  >(false);
  const [engProficiencyState, setEngProficiencyState] =
    useState<RequestStateType>('initial');
  const [engProficiency, setEngProficiency] = useState<EnglishProficiency>();
  const [engProficiencyError, setEngProficiencyError] = useState<IError | null>(
    null
  );

  const [hasLearnerIdentityReq, setHasLearnerIdentityReq] = useState<
    boolean | undefined
  >(false);
  const [learnerIdentityState, setLearnerIdentityState] =
    useState<RequestStateType>('initial');
  const [learnerIdentity, setLearnerIdentity] = useState<IdentityInfo>();
  const [learnerIdentityError, setLearnerIdentityError] =
    useState<IError | null>(null);

  const fetchAdmittanceState = async (
    id: string,
    regId: string,
    isViewOnly?: boolean
  ) => {
    if (!id || !regId) return;
    try {
      setAdmittanceStatusState('loading');
      const response = await api.learnerRegistration.getAdmittanceStatus(
        id,
        regId,
        getBearerToken
      );
      if (!response.ok) throw await response;
      if (response.status === 204) setAdmittanceStatus(undefined);
      else {
        const parsedResponse = (await response.json()) as AdmittanceResponse;

        if (parsedResponse && parsedResponse.admittanceLearnerData) {
          // admittance returns the same keys as learner-profile API but with different capitalisation
          // Modifying the keys on admittance learner data to align with the other responses
          {
            Object.entries(parsedResponse.admittanceLearnerData).forEach(
              ([key, value]) => {
                const newKey = key.charAt(0).toUpperCase() + key.slice(1);
                // @ts-ignore
                parsedResponse.admittanceLearnerData[newKey] = value;
                // @ts-ignore
                delete parsedResponse.admittanceLearnerData[key];
              }
            );
          }
        }
        setAdmittanceViewOnly(isViewOnly || false);
        setAdmittanceStatus(parsedResponse);
      }
      setAdmittanceStatusState('success');
    } catch (err) {
      setAdmittanceStatusState('error');
      setAdmittanceStatusError(err as IError);
    }
  };

  const createProductCodeOptions = (
    options: Array<ProductItem>,
    productType: string
  ): ISelectOption[] => {
    const productCodeOption: ISelectOption[] = [];
    options.forEach((item) => {
      if (item.ProductType === productType)
        productCodeOption.push({
          label: item.FriendlyName,
          value: item.ProductCode,
        });
    });
    return productCodeOption;
  };

  const createProductTypeOptions = (
    options: Array<ProductItem>
  ): IProductTypeSelectOption[] => {
    let productTypeArray: Array<string> = [];
    options.forEach((opt) => productTypeArray.push(opt.ProductType));
    productTypeArray = productTypeArray.filter(
      (item, index) => productTypeArray.indexOf(item) === index
    );

    const productTypeOptions: IProductTypeSelectOption[] = [];
    productTypeArray.forEach((typeItem) => {
      const optionItem = {
        value: typeItem,
        label: typeItem,
        products: createProductCodeOptions(options, typeItem),
      };
      productTypeOptions.push(optionItem);
    });

    return productTypeOptions;
  };

  const createAepDocumentOptions = (options: IAEPDocument[]): ISelectOption[] =>
    (options || []).map(
      (type: IAEPDocument): ISelectOption => ({
        label: type.Name,
        value: type.Name,
      })
    );

  const fetchProductList = async () => {
    if (productListState === 'success') return;
    try {
      setProductListState('loading');
      const response = await api.learnerRegistration.getProductList(
        getBearerToken
      );
      if (!response.ok) throw await response;
      if (response.status === 204) {
        setProductList([]);
      } else {
        const parsedResponse = (await response.json()) as Array<ProductItem>;
        setProductList(createProductTypeOptions(parsedResponse));
      }
      setProductListState('success');
    } catch (err) {
      setProductListState('error');
      setProductListError(err as IError);
    }
  };
  const fetchAepOptions = async () => {
    if (aepListState === 'success') return;
    try {
      setAepListState('loading');
      const response = await api.aepDocuments.aepDocumentOptions(
        getBearerToken
      );
      if (!response.ok) throw await response;
      if (response.status === 204) {
        setAepOptionsList([]);
        setDefaultAepDocuments([]);
      } else {
        const parsedResponse = (await response.json()) as Array<IAEPDocument>;
        setAepOptionsList(createAepDocumentOptions(parsedResponse));
        setDefaultAepDocuments(parsedResponse.filter((ad) => ad.IsDefault));
      }
      setAepListState('success');
    } catch (err: any) {
      console.log('err', err);
      setAepListState('error');
    }
  };

  const fetchApplicantIdentity = async (id: string) => {
    try {
      setLearnerIdentityState('loading');
      const response = await api.learnerRegistration.getIdentityInfo(
        id,
        getBearerToken
      );
      if (!response.ok) throw await response;
      if (response.status === 204) {
        setLearnerIdentity(undefined);
        setVerificationComplete(false);
      } else {
        const parsedResult = (await response.json()) as IdentityInfo;
        setLearnerIdentity(parsedResult);
        setVerificationComplete(parsedResult.process.processComplete);
      }
      setLearnerIdentityState('success');
    } catch (err) {
      setLearnerIdentityState('error');
      setLearnerIdentityError(err as IError);
    }
  };

  const fetchEnglishProficiency = async (id: string) => {
    try {
      setEngProficiencyState('loading');
      const response = await api.learnerRegistration.getEnglishProficiency(
        id,
        getBearerToken
      );
      if (!response.ok) throw await response;
      if (response.status === 204) setEngProficiency(undefined);
      else setEngProficiency((await response.json()) as EnglishProficiency);
      setEngProficiencyState('success');
    } catch (err) {
      setEngProficiencyState('error');
      setEngProficiencyError(err as IError);
    }
  };

  useEffect(() => {
    const loadApplicantIdentity = async (): Promise<void> => {
      if (applicantId && activeRegistrations?.length) {
        const currentRequirements =
          activeRegistrations[selectedRegistrationIndex];

        await fetchAdmittanceState(
          applicantId,
          currentRequirements.supersederRegistrationId ||
            currentRequirements.registrationId,
          !!currentRequirements.supersededByRegistrationId ||
            selectedRegistrationIndex > 1
        );

        await fetchProductList();
        await fetchAepOptions();

        const hasIdentityReq = currentRequirements?.requirements.find(
          (r) => r.requirement === RequirementType.GovIdentity
        );
        setHasLearnerIdentityReq(!!hasIdentityReq);
        if (hasIdentityReq) await fetchApplicantIdentity(applicantId);

        const hasEnglishProficiencyReq = currentRequirements?.requirements.find(
          (r) => r.requirement === RequirementType.EnglishProficiency
        );
        setHasEngProficiencyReq(!!hasEnglishProficiencyReq);
        if (hasEnglishProficiencyReq)
          await fetchEnglishProficiency(applicantId);
      }
    };
    loadApplicantIdentity();
  }, [applicantId, activeRegistrations, selectedRegistrationIndex]);

  useEffect(() => {
    if (activeRegistrations?.length && productList?.length) {
      const currentType =
        activeRegistrations[selectedRegistrationIndex]?.productType;
      const currentCode =
        activeRegistrations[selectedRegistrationIndex]?.productCode;
      if (!currentType || !currentCode) return;
      const typeOption = productList.find((i) => i.value === currentType);
      setInitialProductTypeValue(typeOption);
      setInitialProductCodeValue(
        typeOption?.products?.find((i) => i.value === currentCode)
      );
    }
  }, [activeRegistrations, selectedRegistrationIndex, productList]);

  useEffect(() => {
    if (activeRegistrations?.length && partnerList?.length) {
      const currentPartner =
        activeRegistrations[selectedRegistrationIndex]?.partnerId;
      if (!currentPartner) return;
      const partnerOption = partnerList.find((i) => i.value === currentPartner);

      setInitialPartner(partnerOption);
    }
  }, [partnerList, activeRegistrations, selectedRegistrationIndex]);

  const submitAdmittanceAccept = async (values: {
    Comment: string;
    productType: IProductTypeSelectOption;
    productCode: ISelectOption;
    [PARTNER]?: ISelectOption;
    acceptancePath: boolean;
    aepDocuments: Array<{ name: string; status: string }>;
  }): Promise<void> => {
    if (!activeRegistrations?.[selectedRegistrationIndex] || !applicantId)
      return;
    setAdmittanceUpdateInProgress(true);

    const originalCode =
      activeRegistrations?.[selectedRegistrationIndex].productCode;
    let payload: any = null;

    if (
      originalCode !== values.productCode.value ||
      values.acceptancePath ||
      values[PARTNER]
    ) {
      payload = {
        productType: values.productType.value,
        productCode: values.productCode.value,
        comment: values.Comment,
        aepDocuments: values.aepDocuments,
        partnerId: values[PARTNER]?.value,
      };
    }

    try {
      const response = await api.learnerRegistration.acceptAdmittance(
        applicantId,
        activeRegistrations?.[selectedRegistrationIndex].registrationId,
        getBearerToken,
        payload
      );
      if (!response.ok) throw await response;
      else {
        const currentRequirements =
          activeRegistrations[selectedRegistrationIndex];
        await fetchAdmittanceState(
          applicantId,
          currentRequirements.registrationId
        );
      }
      if (originalCode !== values.productCode.value)
        setChangedProductOnAccept(values.productCode.value);
      setAdmittanceUpdateInProgress(false);
    } catch (err) {
      setAdmittanceUpdateInProgress(false);
      setAdmittanceStatusError(err as IError);
    }
  };

  const submitAdmittanceReject = async (
    commentString: string
  ): Promise<void> => {
    if (!activeRegistrations?.[selectedRegistrationIndex] || !applicantId)
      return;
    setAdmittanceUpdateInProgress(true);

    try {
      const response = await api.learnerRegistration.rejectAdmittance(
        applicantId,
        activeRegistrations?.[selectedRegistrationIndex].registrationId,
        { comment: commentString },
        getBearerToken
      );
      if (!response.ok) throw await response;
      else {
        const currentRequirements =
          activeRegistrations[selectedRegistrationIndex];
        await fetchAdmittanceState(
          applicantId,
          currentRequirements.registrationId
        );
      }
      setAdmittanceUpdateInProgress(false);
    } catch (err) {
      setAdmittanceUpdateInProgress(false);
      setAdmittanceStatusError(err as IError);
    }
  };

  const submitAdmittanceUndo = async (): Promise<void> => {
    if (!activeRegistrations?.[selectedRegistrationIndex] || !applicantId)
      return;
    setAdmittanceUpdateInProgress(true);
    const regId =
      activeRegistrations?.[selectedRegistrationIndex]
        .supersederRegistrationId ||
      activeRegistrations?.[selectedRegistrationIndex].registrationId;
    try {
      const response = await api.learnerRegistration.undoAdmittance(
        applicantId,
        regId,
        getBearerToken
      );
      if (!response.ok) throw await response;
      else {
        await refetchActiveRegistration();
        const currentRequirements =
          activeRegistrations[selectedRegistrationIndex];
        await fetchAdmittanceState(
          applicantId,
          currentRequirements.supersederRegistrationId ||
            currentRequirements.registrationId,
          !!currentRequirements.supersededByRegistrationId
        );
      }
      setAdmittanceUpdateInProgress(false);
    } catch (err) {
      setAdmittanceUpdateInProgress(false);
      setAdmittanceStatusError(err as IError);
    }
  };

  const RegistrationStatusMsg = () => {
    if (
      activeRegistrationState === 'loading' ||
      activeRegistrationState === 'error'
    )
      return null;
    if (
      admittanceStatusState === 'initial' ||
      admittanceStatusState === 'loading'
    )
      return null;
    if (!activeRegistrations?.[selectedRegistrationIndex])
      return (
        <p>This applicant doesn&apos;t have any associated registrations</p>
      );

    const currentRequirements = activeRegistrations[selectedRegistrationIndex];
    const { productCode } = activeRegistrations[selectedRegistrationIndex];

    const admissionDecision = currentRequirements?.requirements.find(
      (r) => r.requirement === RequirementType.AdmissionDecision
    );
    const admissionDecisionIndex = currentRequirements?.requirements
      .map((e) => e.requirement)
      .indexOf(RequirementType.AdmissionDecision);
    const admissionStatus = admittanceStatus?.status;

    if (changedProductOnAccept) {
      return (
        <p>
          Registration for <strong>{productCode}</strong> has been switched to{' '}
          <strong>{changedProductOnAccept}</strong> and accepted
        </p>
      );
    }

    if (currentRequirements.status === 'Superseded') {
      const replacementProduct = activeRegistrations?.find(
        (i) =>
          i.registrationId === currentRequirements.supersededByRegistrationId
      );
      return (
        <p>
          Registration for <strong>{productCode}</strong> has been switched to{' '}
          <strong>{replacementProduct?.productCode}</strong>
        </p>
      );
    }

    // rejection has priority over pending
    if (admissionStatus === AdmittanceStatusEnum.Rejected) {
      return (
        <p>
          Registration for <strong>{productCode}</strong> has been rejected
        </p>
      );
    }

    const hasPending = currentRequirements?.requirements.some(
      (r, i) =>
        r.status === RequirementStatus.Pending && i < admissionDecisionIndex
    );

    if (hasPending) {
      return (
        <p>
          Registration for <strong>{productCode}</strong> is still in progress
          and doesn&apos;t require any Admission Decision to be made
        </p>
      );
    }

    if (admissionStatus === AdmittanceStatusEnum.Accepted) {
      if (currentRequirements.supersederRegistrationId) {
        const replacedProduct = activeRegistrations?.find(
          (i) =>
            i.registrationId === currentRequirements.supersederRegistrationId
        );
        return (
          <p>
            Registration for <strong>{productCode}</strong> has been accepted
            and replaced <strong>{replacedProduct?.productCode}</strong>
          </p>
        );
      }
      return (
        <p>
          Registration for <strong>{productCode}</strong> has been accepted
        </p>
      );
    }

    if (!admissionDecision || (admissionDecision && !admissionStatus)) {
      return (
        <p>
          This registration for <strong>{productCode}</strong> doesn&apos;t
          require any further Admission Decision to be made
        </p>
      );
    }

    const identityProcessComplete = learnerIdentity?.process?.processComplete;

    if (!identityProcessComplete) {
      return (
        <p>
          Registration for <strong>{productCode}</strong> requires Identity
          Checks to be complete before an Admission Decision can be taken
        </p>
      );
    }

    return (
      <p>
        Registration for <strong>{productCode}</strong> is Ready for Review
      </p>
    );
  };

  const UndoAdmittanceCta = () => {
    if (
      admittanceViewOnly ||
      (admittanceStatus?.status !== AdmittanceStatusEnum.Accepted &&
        admittanceStatus?.status !== AdmittanceStatusEnum.Rejected)
    )
      return null;
    const currentRequirements =
      activeRegistrations?.[selectedRegistrationIndex];
    const paymentComplete = currentRequirements?.requirements.find(
      (r) => r.requirement === RequirementType.TuitionFee
    );
    if (
      currentRequirements?.status === 'Completed' ||
      paymentComplete?.status === 'Fulfilled'
    )
      return null;

    return (
      <div className='d-flex justify-content-center'>
        <UndoConfirmationModal
          admittanceStatus={admittanceStatus!}
          disabled={admittanceUpdateInProgress}
          undoEvent={submitAdmittanceUndo}
        />
      </div>
    );
  };

  const handleAepDocumentsChange = (fields: any, inputValue: any): void => {
    if (inputValue) {
      fields.push({ name: inputValue.value, status: MISSING });
    }
  };

  const RegistrationItemBlock = () => (
    <LearnerRegistrationStatusBlock>
      <h3>Registration Status</h3>
      <WithLoading
        loading={activeRegistrationState === 'loading'}
        loadingText='Loading registration list for applicant'
      >
        <WithErrorHandling small error={activeRegistrationError as IError}>
          <WithLoading
            loading={
              admittanceStatusState === 'initial' ||
              admittanceStatusState === 'loading'
            }
            loadingText='Loading admittance status for registration'
          >
            <RegistrationStatusMsg />
            <WithErrorHandling
              small
              error={admittanceStatusError || productListError}
            >
              <UndoAdmittanceCta />
              {verificationComplete && admittanceStatus?.status === AdmittanceStatusEnum.Initiated && (
                <AdmissionDecisionBlock>
                  <ButtonGroup className='mb-2'>
                    <ToggleButton
                      key='1'
                      id='radio-1'
                      type='radio'
                      variant={
                        admissionDecisionChoice === 'approve'
                          ? 'success'
                          : 'secondary'
                      }
                      name='radio'
                      value='approve'
                      checked={admissionDecisionChoice === 'approve'}
                      onChange={(e: any) => {
                        setAdmissionDecisionChoice(e.currentTarget.value);
                      }}
                    >
                      Approve
                    </ToggleButton>
                    <ToggleButton
                      key='2'
                      id='radio-2'
                      type='radio'
                      variant={
                        admissionDecisionChoice === 'reject'
                          ? 'success'
                          : 'secondary'
                      }
                      name='radio'
                      value='reject'
                      checked={admissionDecisionChoice === 'reject'}
                      onChange={(e: any) => {
                        setAdmissionDecisionChoice(e.currentTarget.value);
                        setRegistrationIsEditable(false);
                      }}
                    >
                      Reject
                    </ToggleButton>
                  </ButtonGroup>
                  {!admissionDecisionChoice && (
                    <p>Select whether to accept or reject the application</p>
                  )}
                  {admissionDecisionChoice === 'approve' && (
                    <Form
                      onSubmit={() => {}}
                      keepDirtyOnReinitialize
                      initialValues={{
                        [COMMENT]: '',
                        [PARTNER]: initialPartner,
                        [PRODUCT_CODE]: initialProductCodeValue,
                        [PRODUCT_TYPE]: initialProductTypeValue,
                        [ADMISSION_DECISION]: '',
                        [ACCEPTANCE_PATH]: false,
                        [AEP_DOCUMENTS]: defaultAepDocuments.map(
                          (ad) =>
                            ({
                              name: ad.Name,
                              status: OK,
                            } as ILearnerAEPDocument)
                        ),
                      }}
                      mutators={{
                        ...arrayMutators,
                      }}
                      validate={(values) => {
                        const errors: IFormErrors = {};

                        if (
                          values[ADMISSION_DECISION] === ACCEPT &&
                          (!values[PRODUCT_CODE] ||
                            values[PRODUCT_CODE].value ===
                              emptySelectOption.value)
                        ) {
                          errors[PRODUCT_CODE] = PRODUCT_CODE_ERROR;
                        }
                        return errors;
                      }}
                      render={({ invalid, values }) => (
                        <form className='d-flex flex-column gap-2'>
                          <div className='d-flex justify-content-center align-items-center'>
                            {regLocation?.country === UNITED_STATES_ISO && (
                              <CountryMessage data-testid='country-message'>
                                This is a US applicant from {regLocation.state}{' '}
                                state.
                              </CountryMessage>
                            )}
                          </div>
                          <ProductTypeSelect />
                          <ProductCodeSelect />
                          <Row>
                            <Col xs={4}>
                              <span>Acceptance path</span>
                            </Col>
                            <Col xs={8}>
                              <Field
                                fieldType='checkbox'
                                name={ACCEPTANCE_PATH}
                                render={renderCheckbox}
                                type='checkbox'
                                label='Accepted Evaluation Pending'
                              />
                            </Col>
                          </Row>
                          {values[ACCEPTANCE_PATH] && (
                            <>
                              <Row>
                                <Col xs={4}>
                                  <span>Accepted Evaluation Pending</span>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <Row>
                                    <Col xs={3} />
                                    <RadioButtonCol xs={1}>
                                      <RadioLabel>Ok</RadioLabel>
                                    </RadioButtonCol>
                                    <RadioButtonCol xs={2}>
                                      <RadioLabel>Missing</RadioLabel>
                                    </RadioButtonCol>
                                    <RadioButtonCol xs={3}>
                                      <RadioLabel>
                                        Incorrect/Insufficient
                                      </RadioLabel>
                                    </RadioButtonCol>
                                    <RadioButtonCol xs={2}>
                                      <RadioLabel>Unreadable</RadioLabel>
                                    </RadioButtonCol>
                                  </Row>
                                  <FieldArray name={AEP_DOCUMENTS}>
                                    {({ fields }) => (
                                      <div className='d-flex flex-column row-gap-1'>
                                        {fields.map((document, index) => (
                                          <div key={document}>
                                            <Row>
                                              <LongTextColumn xs={3}>
                                                {
                                                  values.aepDocuments[index]
                                                    .name
                                                }
                                              </LongTextColumn>
                                              <RadioButtonCol xs={1}>
                                                <Field
                                                  fieldType='RADIO'
                                                  name={`${document}.status`}
                                                  render={renderCheckbox}
                                                  type='radio'
                                                  value={OK}
                                                />
                                              </RadioButtonCol>
                                              <RadioButtonCol xs={2}>
                                                <Field
                                                  fieldType='RADIO'
                                                  name={`${document}.status`}
                                                  render={renderCheckbox}
                                                  type='radio'
                                                  value={MISSING}
                                                />
                                              </RadioButtonCol>
                                              <RadioButtonCol xs={3}>
                                                <Field
                                                  fieldType='RADIO'
                                                  name={`${document}.status`}
                                                  render={renderCheckbox}
                                                  type='radio'
                                                  value={INCORRECT}
                                                />
                                              </RadioButtonCol>
                                              <RadioButtonCol xs={2}>
                                                <Field
                                                  fieldType='RADIO'
                                                  name={`${document}.status`}
                                                  render={renderCheckbox}
                                                  type='radio'
                                                  value={UNREADBLE}
                                                />
                                              </RadioButtonCol>

                                              <Col xs={1}>
                                                {!defaultAepDocuments.find(
                                                  (ad) =>
                                                    ad.Name ===
                                                    values.aepDocuments[index]
                                                      .name
                                                ) && (
                                                  <OverlayTrigger
                                                    placement='top'
                                                    overlay={
                                                      <Tooltip id='button-tooltip'>
                                                        Remove
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <TrashButton
                                                      onClick={() =>
                                                        fields.remove(index)
                                                      }
                                                    >
                                                      <FaTrashAlt
                                                        style={{
                                                          color: 'grey',
                                                        }}
                                                      />
                                                    </TrashButton>
                                                  </OverlayTrigger>
                                                )}
                                              </Col>
                                            </Row>
                                          </div>
                                        ))}
                                        {values.aepDocuments.length < 6 && (
                                          <div>
                                            <InputableSelect
                                              minLength={4}
                                              maxLength={40}
                                              onChange={(inputValue: any) => {
                                                handleAepDocumentsChange(
                                                  fields,
                                                  inputValue
                                                );
                                              }}
                                              options={aepOptionsList.filter(
                                                (ad) =>
                                                  !values.aepDocuments.find(
                                                    (add: { name: string }) =>
                                                      add.name === ad.value
                                                  )
                                              )}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </FieldArray>
                                  <Error name={ACCEPTED_EVALUATION_PENDING} />
                                </Col>
                              </Row>
                            </>
                          )}

                          <Row className='justify-content-center'>
                            <Col>
                              <p>{COMMENT_WARNING}</p>
                              <Field
                                name={COMMENT}
                                validate={composeValidators(minLength(20))}
                                render={renderTextField}
                                maxLength={250}
                              />
                              <p>{invalid && <Error name={COMMENT} />}</p>
                            </Col>
                          </Row>
                          <Row className='justify-content-center'>
                            <Button
                              disabled={invalid || admittanceUpdateInProgress}
                              onClick={() => submitAdmittanceAccept(values)}
                            >
                              <span>Accept</span>
                            </Button>
                          </Row>
                        </form>
                      )}
                    />
                  )}
                  {admissionDecisionChoice === 'reject' && (
                    <Form
                      onSubmit={() => {}}
                      initialValues={{ [COMMENT]: '' }}
                      render={({ invalid, values }) => (
                        <form>
                          <Row className='justify-content-center'>
                            {regLocation?.country === UNITED_STATES_ISO && (
                              <div className='d-flex justify-content-center align-items-center'>
                                <CountryMessage data-testid='country-message'>
                                  This is a US applicant from{' '}
                                  {regLocation.state} state.
                                </CountryMessage>
                              </div>
                            )}
                          </Row>
                          <Row className='justify-content-center'>
                            <Col>
                              <p>{COMMENT_WARNING}</p>
                              <Field
                                name={COMMENT}
                                validate={composeValidators(
                                  minLength(20),
                                  commentRequired(
                                    'Please include a comment on rejecting the application'
                                  )
                                )}
                                render={renderTextField}
                                maxLength={250}
                              />
                              <p>{invalid && <Error name={COMMENT} />}</p>
                            </Col>
                          </Row>
                          <Row className='justify-content-center'>
                            <Button
                              variant='danger'
                              disabled={invalid || admittanceUpdateInProgress}
                              onClick={() =>
                                submitAdmittanceReject(values[COMMENT])
                              }
                            >
                              <span>Reject</span>
                            </Button>
                          </Row>
                        </form>
                      )}
                    />
                  )}
                </AdmissionDecisionBlock>
              )}
            </WithErrorHandling>
            <PartnerDetailsComponent
              partnerId={
                activeRegistrations &&
                activeRegistrations[selectedRegistrationIndex]?.partnerId
              }
            />
            {!!admittanceStatus && (
              <LearnerRegistrationApplicantBlock>
                <h3>Admission Decision</h3>
                <ul>
                  {!!admittanceStatus.admittanceLearnerData && (
                    <li>
                      <span>Registration Details:</span>{' '}
                      <AdmittanceStatusModal
                        admittanceStatus={admittanceStatus}
                      />
                    </li>
                  )}
                  <li>
                    <span>Hubspot Ticket:</span>
                    {admittanceStatus?.decisionTicketUrl ? (
                      <a
                        href={admittanceStatus.decisionTicketUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {admittanceStatus.decisionTicketUrl}
                      </a>
                    ) : (
                      'N/A'
                    )}
                                  </li>
                  {(admittanceStatus.status === AdmittanceStatusEnum.Accepted || admittanceStatus.status === AdmittanceStatusEnum.Rejected) && (
                    <li>
                        <span>Reviewed:</span> {admittanceStatus?.decisionMadeBy} ({formatDate(admittanceStatus?.decisionDate,'DD MMM YYYY hh:mm A')})
                    </li>
                  )}
                  {!!admittanceStatus.comment?.length && (<li>
                    <span>Comment:</span> {admittanceStatus?.comment}
                                  </li>)}
                {admittanceStatus.status === AdmittanceStatusEnum.Accepted && !!admittanceStatus.missingAepDocuments && (
                    <li>
                        <span>AEP:</span> {admittanceStatus.missingAepDocuments?.length > 0 ? "Pending" :"Completed" }
                    </li>
                )}
                {admittanceStatus.status === AdmittanceStatusEnum.Accepted && !!admittanceStatus.missingAepDocuments?.length && (
                    <>
                    {
                        admittanceStatus.missingAepDocuments.map(
                            (item, index) => (
                                <li key={index}>
                                    <span>{item.name}:</span>
                                    {item.status}
                                </li>
                            )
                        )
                        }
                    </>
                )}
                </ul>
              </LearnerRegistrationApplicantBlock>
            )}
          </WithLoading>
        </WithErrorHandling>
        {hasLearnerIdentityReq && (
          <LearnerRegistrationApplicantBlock>
            <h3>Verification Steps</h3>
            <WithLoading
              loading={
                learnerIdentityState === 'initial' ||
                learnerIdentityState === 'loading'
              }
              loadingText='Loading Verification steps for applicant'
            >
              <WithErrorHandling small error={learnerIdentityError}>
                {learnerIdentity && (
                  <VerificationSteps
                    refreshVerificationSteps={fetchApplicantIdentity}
                    getBearerToken={getBearerToken}
                    applicantId={applicantId || ''}
                    canEditDocuments={registrationIsEditable}
                    learnerIdentity={learnerIdentity}
                  />
                )}
              </WithErrorHandling>
            </WithLoading>
          </LearnerRegistrationApplicantBlock>
        )}

        {hasEngProficiencyReq && (
          <LearnerRegistrationApplicantBlock>
            <h3>English Proficiency</h3>
            <WithLoading
              loading={
                engProficiencyState === 'initial' ||
                engProficiencyState === 'loading'
              }
              loadingText='Loading English Proficiency for applicant'
            >
              <WithErrorHandling small error={engProficiencyError}>
                {!engProficiency && (
                  <p>
                    Applicant hasn&apos;t submitted their english proficiency
                    yet
                  </p>
                )}
                {engProficiency && (
                  <ul>
                    <li>
                      <span>Native English Speaker:</span>{' '}
                      {engProficiency?.IsNativeEnglishSpeaker ? 'Yes' : 'No'}
                    </li>
                    {!engProficiency?.IsNativeEnglishSpeaker && (
                      <li>
                        <span>Primary Education was in English:</span>{' '}
                        {engProficiency?.IsEnglishPrimaryEducationLanguage
                          ? 'Yes'
                          : 'No'}
                      </li>
                    )}
                  </ul>
                )}
              </WithErrorHandling>
            </WithLoading>
          </LearnerRegistrationApplicantBlock>
        )}
      </WithLoading>
    </LearnerRegistrationStatusBlock>
  );

  return (
    <div>
      <LearnerRegistrationHeading>
        <h1>Learner Registration</h1>
        <Button
          as={Link as any}
          to={`/learners/${applicantId}`}
          variant='success'
        >
          Learner Record
        </Button>
      </LearnerRegistrationHeading>
      <RegistrationsListComponent
        activeRegistrations={activeRegistrations}
        activeRegistrationState={activeRegistrationState}
        selectedRegistrationIndex={selectedRegistrationIndex}
        setSelectedRegistrationIndex={setSelectedRegistrationIndex}
      />
      <RegistrationItemBlock />
      <PersonalDetailsComponent
        getBearerToken={getBearerToken}
        canEditPersonal={registrationIsEditable}
        setLocation={setRegLocation}
      />
      <PreviousEducationComponent
        getBearerToken={getBearerToken}
        canEditEducation={registrationIsEditable}
      />
      <ApplicantFilesComponent getBearerToken={getBearerToken} />
    </div>
  );
};

export default LearnerRegistration;
