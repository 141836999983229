import React, { useState } from 'react';
import { Button, Row, Col, Form, InputGroup } from 'react-bootstrap';

import { FiEdit, FiSave } from 'react-icons/fi';
import { GiCancel } from 'react-icons/gi';
import { EditButton, FieldRow } from './styled-component';
import TextField from './text-edit-component';

interface IProps {
  oldValue: string;
  canEdit: boolean;
  propertyName: string;
  fieldType: string;
  handleNewValue: (newValue: string) => Promise<void>;
}

const TextFieldEditor: React.FunctionComponent<IProps> = ({
  oldValue,
  canEdit,
  propertyName,
  fieldType,
  handleNewValue,
}: IProps) => {
  const [editing, setEditing] = useState(false);
  const [fieldValue, setNewFieldValue] = useState(oldValue);
  const [validationError, setFormError] = useState<null | string>(null);

  const edit = () => {
    setEditing(true);
    setNewFieldValue(oldValue);
  };

  const cancel = () => {
    setEditing(false);
    setNewFieldValue(oldValue);
    setFormError(null);
  };

  const save = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    await handleNewValue(fieldValue);
    setEditing(false);
  };

  if (editing) {
    return (
      <>
        <Form onSubmit={save}>
          <InputGroup hasValidation>
            <TextField
              fieldValue={fieldValue}
              fieldType={fieldType}
              propertyName={propertyName}
              setFormError={setFormError}
              setNewFieldValue={setNewFieldValue}
              validationError={validationError}
              actions={
                <>
                  <Button
                    data-testid='field-submit'
                    variant='outline-secondary'
                    size='sm'
                    disabled={!!validationError}
                    type='submit'
                  >
                    <FiSave />
                  </Button>
                  <Button
                    variant='outline-secondary'
                    size='sm'
                    onClick={cancel}
                  >
                    <GiCancel />
                  </Button>
                </>
              }
            />
          </InputGroup>
        </Form>
      </>
    );
  }
  return (
    <>
      <Row>
        <Col id={propertyName} xs={9}>
          <span> {fieldValue} </span>
        </Col>
        <Col xs={3}>
          <FieldRow>
            {canEdit && (
              <EditButton
                data-testid='field-submit'
                variant='outline-secondary'
                size='sm'
                onClick={edit}
              >
                <FiEdit />
              </EditButton>
            )}
          </FieldRow>
        </Col>
      </Row>
    </>
  );
};
export default TextFieldEditor;
