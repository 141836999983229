
 
import React from 'react';
import { Row } from 'react-bootstrap';
import { IEvent, IEventDetails, ICustomEventProps } from '../learner-history-model';
import EventProp from './utils/event-prop';
import EventPropStartDate from './utils/event-prop-start-date';

export const readmissioRequestCanceledEvent: (event: IEvent) => IEventDetails = (event: IEvent) => ({
  metadata: {
    type: {
      category: 'Program Enrollment',
      eventName: 'Request Canceled',
    },
    date: event.updatedDateTime,
    editor: event.editor,
  },
  element: <Event event={event} />,
});

const Event: React.FunctionComponent<ICustomEventProps> = (props: ICustomEventProps) => {
  const { event } = props;

  return (
    <Row>
      <EventProp name="Product Type" value={event.productType} primary />
      <EventProp name="Product Code" value={event.productCode} primary />
      <EventPropStartDate name="Start Date:" value={event.startDate} />
    </Row>
  );
};
