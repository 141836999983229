import { AppError } from './app-error';

const getResponseValue = (response: Response) => {
  if (response.headers.get('content-type')?.includes('application/json')) {
    return response.json();
  }

  return response.text();
};

export const appFetch = async <T,>(
  url: string,
  init?: RequestInit
): Promise<T | undefined> => {
  const response = await fetch(url, init);

  if (!response.ok) {
    throw AppError.fromError(await getResponseValue(response), response.status);
  }

  if (response.status === 204) return undefined;

  return getResponseValue(response);
};
