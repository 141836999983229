import * as React from 'react';
import api from '../../../../shared/api/adminUI.api';
import SubmitButton from '../../submit-button-component/submit-button-component';
import CourseList from '../../courses-render-function/courses-checkbox-list';
import errorHandling from '../../../helper-components/alert-component.component';
import { WithLoading } from '../../../helper-components/loading.component';
import { LearningPathContainer } from '../../styled-components';
import {
  IElectiveCoursesProps,
  IElectiveCoursesState,
  IElectiveCourse,
} from './elective-courses.model';
import { STEP1 } from '../specializations-courses/specialization-course';
import { AuthContext } from '../../../auth/auth-context';
import { StepperAction, StepperContent } from '../../../ui/stepper';

export const STEP2 = 'step-two';

const convertMapToArray = (map: Map<string, boolean>) => Object.keys(Object.fromEntries(map))
  .filter((key) => Object.fromEntries(map)[key])
  .map((key) => key);

const areEnoughElectivesSelected = (
  checkedItems: Map<string, boolean>,
  minCoursesRequired: number,
  pendingSave: boolean,
) => !(convertMapToArray(checkedItems).length >= minCoursesRequired) || pendingSave;

const setCheckedItems = (electiveCoursesData: IElectiveCourse[]) => {
  const checkBoxesData = electiveCoursesData.map((course: IElectiveCourse) => ({
    key: course.course.id,
    value: course.selected,
  }));
  return new Map(checkBoxesData.map((i) => [i.key, i.value]));
};

class ElectiveCourses extends React.PureComponent<
IElectiveCoursesProps,
IElectiveCoursesState
> {
  constructor(props: IElectiveCoursesProps) {
    super(props);
    this.state = {
      electiveCoursesData: {
        items: [],
        minCoursesRequired: 0,
      },
      checkedItems: new Map(),
      pending: true,
      pendingSave: false,
    };
  }

  public componentDidMount() {
    const { getBearerToken } = this.context as { getBearerToken: () => Promise<string> };
    const { learnerId, programEnrollmentId } = this.props;
    this.loadElectiveData(learnerId, programEnrollmentId, getBearerToken);
  }

  private onSave = async (
    e: React.FormEvent,
    checkedItems: Map<string, boolean>,
    resolve: (data: string) => void,
    learnerId: string,
    programEnrollmentId: string,
    getBearerToken: () => Promise<string>,
  ): Promise<void> => {
    e.preventDefault();

    const electiveData = checkedItems.size > 0 ? convertMapToArray(checkedItems) : [];
    try {
      this.setState({ pendingSave: true });
      const electiveResponse = await api.learningPathPlanner.electives.setSelectedElectives(
        learnerId,
        programEnrollmentId,
        getBearerToken,
        electiveData,
      );
      if (!electiveResponse.ok) throw await electiveResponse.json();
      this.setState({ pendingSave: false });
      resolve('');
    } catch (error: any) {
      this.setState({ pendingSave: false });
      errorHandling({ error });
    }
  };

  private handleCheckboxChange = (e: React.BaseSyntheticEvent) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    const { checkedItems } = this.state;
    this.setState({ checkedItems: new Map(checkedItems).set(item, isChecked) });
  };

  private readonly loadElectiveData = async (
    learnerId: string,
    programEnrollmentId: string,
    getBearerToken: () => Promise<string>,
  ): Promise<void> => {
    try {
      const electiveResponse = await api.learningPathPlanner.electives.getElectivesData(
        learnerId,
        programEnrollmentId,
        getBearerToken,
      );
      if (!electiveResponse.ok) throw await electiveResponse.json();
      const electiveCoursesData = (await electiveResponse.json()) as any;
      if (electiveCoursesData.items.length === 0) {
        const { setIsElectivesEmpty } = this.props;
        setIsElectivesEmpty(true);
      }
      this.setState({
        pending: false,
        electiveCoursesData,
        checkedItems: setCheckedItems(electiveCoursesData.items),
      });
    } catch (error: any) {
      errorHandling({ error });
      this.setState({
        pending: false,
      });
    }
  };

  render(): JSX.Element {
    const {
      pending,
      electiveCoursesData,
      checkedItems,
      pendingSave,
    } = this.state;
    const { minCoursesRequired } = electiveCoursesData;
    const { getBearerToken } = this.context as { getBearerToken: () => Promise<string> };

    const {
      stepperContext,
      isIndividualCourseProgram,
      learnerProductCode,
      learnerId,
      programEnrollmentId,
    } = this.props;

    const { goAt } = stepperContext;
    const back = () => goAt(STEP1);

    return (
      <WithLoading
        loading={pending}
        loadingText="Loading elective courses ..."
        spinner
      >
        <LearningPathContainer>
          <StepperContent
            actions={(
              <>
                {!isIndividualCourseProgram && <StepperAction onClick={back}>Back</StepperAction>}
                <SubmitButton
                  isDisabled={
                    areEnoughElectivesSelected(
                      checkedItems,
                      minCoursesRequired,
                      pendingSave,
                    )
}
                  checkedItems={checkedItems}
                  pendingSave={pendingSave}
                  onSubmit={this.onSave}
                  learnerId={learnerId}
                  programEnrollmentId={programEnrollmentId}
                  getBearerToken={getBearerToken}
                />
              </>
            )}
          >
            {electiveCoursesData.items.length > 0
              ? (
                <CourseList
                  courseData={electiveCoursesData.items}
                  checkedItems={checkedItems}
                  handleChange={this.handleCheckboxChange}
                  specialisations={false}
                  learnerProductCode={learnerProductCode}
                />
              ) : <p> No available electives</p>}
          </StepperContent>
        </LearningPathContainer>
      </WithLoading>
    );
  }
}
ElectiveCourses.contextType = AuthContext;
export default ElectiveCourses;
