import React, { useRef } from 'react';
import ReSelect, {
  ActionMeta,
  OnChangeValue,
  Props as ReProps,
} from 'react-select';
import { HelperText } from '../helper-text/helper-text';
import { FormElement } from '../form-element/form-element';
import { useField, UseFieldConfig } from 'react-final-form';
import styles from './select.module.scss';

type BaseProps<T, IsMulti extends boolean = false> = {
  error?: string;
  testId?: string;
  className?: string;
} & ReProps<T, IsMulti>;

export const BaseSelect = <T, IsMulti extends boolean>({
  error,
  testId,
  className,
  ...props
}: BaseProps<T, IsMulti>) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const onMenuOpen = () => {
    const { current } = containerRef;
    if (!current) return;

    setTimeout(() => {
      const selectedEl = current.getElementsByClassName(
        'nxu__option--is-selected'
      )[0];

      if (selectedEl) {
        selectedEl.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    }, 15);
  };

  return (
    <div className={className} ref={containerRef} data-testid={testId}>
      <ReSelect classNamePrefix='nxu' onMenuOpen={onMenuOpen} {...props} />

      {error && <HelperText variant='error'>{error}</HelperText>}
    </div>
  );
};

type Props<T, IsMulti extends boolean> = {
  label: string;
  name: string;
  fieldProps?: UseFieldConfig<T, T>;
} & BaseProps<T, IsMulti>;

export const Select = <T, IsMulti extends boolean = false>({
  label,
  name,
  onChange,
  className,
  fieldProps = {},
  ...props
}: Props<T, IsMulti>) => {
  const { input, meta } = useField(name, fieldProps);

  const handleChange = (
    value: OnChangeValue<T, IsMulti>,
    actionMeta: ActionMeta<T>
  ) => {
    onChange?.(value, actionMeta);
    input.onChange(value);
  };

  return (
    <FormElement className={className}>
      <FormElement.Label>{label}</FormElement.Label>
      <BaseSelect<T, IsMulti>
        className={styles.select}
        onChange={handleChange}
        value={input.value}
        error={meta.error}
        {...props}
      />
    </FormElement>
  );
};
