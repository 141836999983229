
 
import React from 'react';
import { Row } from 'react-bootstrap';
import { IEvent, IEventDetails, ICustomEventProps } from '../learner-history-model';
import EventProp from './utils/event-prop';
import { useNationalityOptionsContext } from '../../../shared/providers/nationalityOptionsProvider';

export const learnerProfileLearnerCountryOfResidenceUpdatedEventSelector: (event:IEvent) => IEventDetails = (event:IEvent) => ({
  metadata: {
    type: {
      category: 'Learner Profile',
      eventName: 'Country of Residence Updated',
    },
    date: event.updatedDateTime,
    editor: event.actor,
  },
  element: <Event event={event} />,
});

const Event: React.FunctionComponent<ICustomEventProps> = (props: ICustomEventProps) => {
  const { event } = props;
  const { countryOptions } = useNationalityOptionsContext();

  const country = countryOptions.find((c) => c.value === event.countryOfResidence);

  return (
    <Row>
      <EventProp name="Country of Residence" value={country ? country.label : event.countryOfResidence} primary />
    </Row>
  );
};
