 
import { createSelector } from 'reselect';
import { IRootState } from '../reducers';
import { ILearnerProfileState } from '../reducers/learner-profile/learner-profile.reducer';
import { IPaymentStatus } from '../../components/learner-info/learner-info.model';
import {
  IlearnerInfoEditPermissionObject, IlearnerAchievementsPermissionObject, ILearnerAchievementDetails, ILPPPermissionObject,
} from '../actions/learner-profile.action';
import Learner, { LearnerFlags } from '../../models/Learner';
import ProgramEnrollment from '../../models/ProgramEnrollment';
import IError from '../../models/Error';
import { IApplicantInfo } from '../../components/admissions/admissions.model';

export const getLearnerProfileState = (
  state: IRootState,
): ILearnerProfileState => state.learnerProfileState;

export const getLearnerProfile = createSelector(
  [getLearnerProfileState],
  (learnerProfileState: ILearnerProfileState): Learner => learnerProfileState.learnerProfile,
);

export const getLearnerFlags = createSelector(
  [getLearnerProfileState],
  (learnerProfileState: ILearnerProfileState): LearnerFlags => learnerProfileState.learnerFlags,
);

export const getLearnerFlagsPending = createSelector(
  [getLearnerProfileState],
  (
    learnerProfileState: ILearnerProfileState,
  ): boolean => learnerProfileState.learnerFlagsPending,
);

export const getLearnerProgramEnrollment = createSelector(
  [getLearnerProfileState],
  (learnerProfileState: ILearnerProfileState): ProgramEnrollment[] => learnerProfileState.programEnrollments,
);

export const getLearnerProductType = createSelector(
  [getLearnerProfileState],
  (
    learnerProfileState: ILearnerProfileState,
  ): string => learnerProfileState.learnerProfile.ProductType,
);

export const getLearnerProductCode = createSelector(
  [getLearnerProfileState],
  (
    learnerProfileState: ILearnerProfileState,
  ): string => learnerProfileState.learnerProfile.ProductCode,
);

export const getLearnerError = createSelector(
  [getLearnerProfileState],
  (
    learnerProfileState: ILearnerProfileState,
  ): IError | null => learnerProfileState.learnerProfileError,
);

export const getLearnerStatus = createSelector(
  [getLearnerProfileState],
  (learnerProfileState: ILearnerProfileState): boolean => learnerProfileState.learnerProfilePending,
);
export const getLearnerAchievements = createSelector(
  [getLearnerProfileState],
   
  (learnerProfileState: ILearnerProfileState): ILearnerAchievementDetails => learnerProfileState.learnerAchievements,
);

export const getLearnerApplicantInfoPending = createSelector(
  [getLearnerProfileState],
  (learnerProfileState: ILearnerProfileState): boolean => learnerProfileState.learnerApplicantInfoPending,
);

export const getLearnerApplicantInfo = createSelector(
  [getLearnerProfileState],
   
  (learnerProfileState: ILearnerProfileState): IApplicantInfo => learnerProfileState.learnerApplicantInfo,
);

export const getLearnerInfoPermissions = createSelector(
  [getLearnerProfileState],
  (
    learnerProfileState: ILearnerProfileState,
  ): IlearnerInfoEditPermissionObject => learnerProfileState.learnerInfoPermissionsObject,
);

export const getLearnerInfoPermissionsPending = createSelector(
  [getLearnerProfileState],
  (
    learnerProfileState: ILearnerProfileState,
  ): boolean => learnerProfileState.learnerInfoPermissionsPending,
);

export const getLearnerInfoPermissionsError = createSelector(
  [getLearnerProfileState],
  (
    learnerProfileState: ILearnerProfileState,
  ): IError | null => learnerProfileState.learnerInfoPermissionsError,
);

export const getLearnerPayment = createSelector(
  [getLearnerProfileState],
  (
    learnerProfileState: ILearnerProfileState,
  ): IPaymentStatus => learnerProfileState.learnerPaymentStatus,
);
export const getLearnerPaymentPendig = createSelector(
  [getLearnerProfileState],
  (
    learnerProfileState: ILearnerProfileState,
  ): boolean => learnerProfileState.learnerPaymentStatusPending,
);
export const getLearnerPaymentError = createSelector(
  [getLearnerProfileState],
  (
    learnerProfileState: ILearnerProfileState,
  ): IError | null => learnerProfileState.learnerPaymentStatusError,
);


export const getUpdateAchievementStatusPending = createSelector(
  [getLearnerProfileState],
  (
    learnerProfileState: ILearnerProfileState,
  ): boolean => learnerProfileState.updateAchievementStatusPending,
);

export const getLearnerAchievementsPermissions = createSelector(
  [getLearnerProfileState],
  (
    learnerProfileState: ILearnerProfileState,
  ): IlearnerAchievementsPermissionObject => learnerProfileState.learnerAchievementsPermissions,
);
export const getLearnerAchievementsPending = createSelector(
  [getLearnerProfileState],
  (
    learnerProfileState: ILearnerProfileState,
  ): boolean => learnerProfileState.learnerAchievementsPending,
);

export const getLearnerAchievementsPermissionsError = createSelector(
  [getLearnerProfileState],
  (
    learnerProfileState: ILearnerProfileState,
  ): IError | null => learnerProfileState.learnerAchievementsPermissionsError,
);

export const getLPPPermissions = createSelector(
  [getLearnerProfileState],
  (
    learnerProfileState: ILearnerProfileState,
  ): ILPPPermissionObject => learnerProfileState.lppPermissions,
);
export const getLPPPermissionsPending = createSelector(
  [getLearnerProfileState],
  (
    learnerProfileState: ILearnerProfileState,
  ): boolean => learnerProfileState.lppPermissionsPending,
);

export const getLPPPermissionsError = createSelector(
  [getLearnerProfileState],
  (
    learnerProfileState: ILearnerProfileState,
  ): IError | null => learnerProfileState.lppPermissionsError,
);


export const getActiveProgramEnrollmentId = createSelector(
  [getLearnerProfileState],
  (learnerProfileState: ILearnerProfileState): string => learnerProfileState.activeProgramEnrollmentId,
);
