import { useQuery } from '@tanstack/react-query';
import Learner from '../../models/Learner';
import { useAuthContext } from '../../components/auth/auth-context';
import { appFetch } from './fetch';
import { AppError } from './app-error';

const getLearner = async (
  learnerId: string,
  getBearerToken: () => Promise<string>,
) => {
  const token = await getBearerToken();

  return appFetch<Learner>(`/api/learner-profile/learners/${learnerId}`, {
    method: 'GET',
    headers: [['Authorization', `Bearer ${token}`]],
  });
};

export const useLearner = (learnerId: string) => {
  const { getBearerToken } = useAuthContext();

  return useQuery({
    queryKey: ['learners', learnerId],
    queryFn: () => getLearner(learnerId, getBearerToken),
    suspense: true,
    retry(failureCount, error) {
      if (failureCount >= 3) return false;
      // if learner does not found, do not call server again
      if (
        error instanceof AppError
        && error.data?.code === 'LEARNER_DOESNOT_EXIST'
      ) {
        return false;
      }
      return true;
    },
  });
};
