 
 
 
 
 


import React, { useEffect, useState } from 'react';
import dompurify from 'dompurify';
import { Modal, Spinner } from 'react-bootstrap';
import errorHandling from '../helper-components/alert-component.component';

export interface LearnerEmail {
  learnerId:string,
  emailId:string,
  timestamp:Date,
  fromNexford:boolean,
  from:string,
  to:string,
  bcc:string,
  subject:string,
  preview:string,
  departments:string[],
  body?:string,
  isHtml?:boolean,
  restricted:boolean,
}

const getEmailBody = async (
  learnerId: string,
  emailId: string,
  getBearerToken: () => Promise<string>,
): Promise<string> => {
  try {
    const token = await getBearerToken();
    const response = await fetch(`/api/email-sync/learners/${learnerId}/emails/${emailId}`, {
      method: 'GET',
      headers: [['Authorization', `Bearer ${token}`]],
    });

    if (response.ok) {
      const email = await response.json() as LearnerEmail;
      return email.body || '';
    }

    throw await response;
  } catch (error: any) {
    errorHandling({ error });
  }

  return '';
};


interface IEmailBodyDialogProps {
  email: LearnerEmail;
  close: () => void;
  getBearerToken: () => Promise<string>,
}

export const EmailBodyDialog: React.FunctionComponent<IEmailBodyDialogProps> = (props: IEmailBodyDialogProps) => {
  const { email, close, getBearerToken } = props;
  const [body, setBody] = useState<string | null | undefined>(email.body);

  useEffect(() => {
    async function getBody() {
      if (email.restricted) {
        setBody('<restricted>');
      } else if (!email.body) {
        const emailBody = await getEmailBody(email.learnerId, email.emailId, getBearerToken);
        email.body = emailBody;
        setBody(emailBody);
      }
    }

    getBody();
  }, [email.body, email.learnerId, email.emailId, email.restricted, getBearerToken]);

  const learnerIdLink = <a href={`/learners/${email.learnerId}`}>{email.learnerId}</a>;

  return (
    <Modal show onHide={close} size="lg">
      <Modal.Header closeButton>
        <h5> {email.subject } </h5>
      </Modal.Header>
      <Modal.Body>
        <div>From: {!email.fromNexford && learnerIdLink} {email.from}</div>
        <div>To: {email.fromNexford && learnerIdLink} {email.to}</div>
        {email.bcc && email.bcc !== ' ' && <div>Bcc: {email.bcc.length}</div>}
        <p>Sent: {email.timestamp.toLocaleString()}</p>
        {body && <EmailBody html={body} />}
        {!body && <Spinner animation="border" />}
        <Modal.Footer />
      </Modal.Body>
    </Modal>
  );
};

const EmailBody = ({ html } : { html: string }): JSX.Element => {
  const sanitizer = dompurify.sanitize;
  return <div dangerouslySetInnerHTML={{ __html: sanitizer(html) }} />;
};
