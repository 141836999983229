import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import api from '../api/adminUI.api';
import IError from '../../models/Error';
import errorHandling from '../../components/helper-components/alert-component.component';
import {
  GET_VOUCHER_DATA_PENDING,
  GET_VOUCHER_DATA_SUCCESS,
  GET_VOUCHER_DATA_ERROR,
  UPDATE_APPLICATION_FIELD_VALUE,
  UPDATE_TUITION_FIELD_VALUE,
} from '../reducers/voucher-code/voucher-code.constants';

import { IVoucherData } from '../reducers/voucher-code/voucher-code.model';
import { INewFieldValue } from '../../components/admissions/admissions.model';
import { switchInvalidCouponCodeReason } from '../../components/helper-components/payment-utility';

export const getVoucherCodeObjectPending = (): AnyAction => action(GET_VOUCHER_DATA_PENDING);
export const getStartDateObjectSuccess = (
  voucherData: IVoucherData,
): AnyAction => action(GET_VOUCHER_DATA_SUCCESS, voucherData);
export const getVoucherCodeObjectFailure = (
  error: IError,
): AnyAction => action(GET_VOUCHER_DATA_ERROR, error);


export const getVoucherCodeInfo = (
  getBearerToken: () => Promise<string>,
  applicantId: string,
) => async (dispatch: Dispatch) => {
  dispatch(getVoucherCodeObjectPending());
  try {
    const response = await api.admissions.getApplicantVoucherCodeData(
      getBearerToken,
      applicantId,
    );
    if (!response.ok) throw await response;
    const data = (await response.json()) as IVoucherData;
    dispatch(getStartDateObjectSuccess(data));
  } catch (error: any) {
    dispatch(getVoucherCodeObjectFailure(error));
    errorHandling({ error });
  }
};

const humanizeErrorMessage = (response: Response, detail: string) => {
  let message = null;
  if (detail) {
    message = switchInvalidCouponCodeReason(detail);
  }

  const error = {
    status: response.status,
    statusText: detail || response.statusText,
    message,
  };

  return error;
};

export const updateApplicationVoucherCodeFieldValue = (
  applicantId: string,
  getBearerToken: () => Promise<string>,
  newFieldValue: INewFieldValue,
) => async (dispatch: Dispatch) => {
  try {
    const url = `/api/admission/applicants/${applicantId}/application-fee/coupon`;
    const token = await getBearerToken();
    const response = await fetch(url, {
      method: 'PUT',
      headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
      body: JSON.stringify(newFieldValue),
    });

    if (!response.ok) {
      const body = await response.json();
      const error = humanizeErrorMessage(response, body.Detail);

      throw error;
    }
    dispatch({ type: UPDATE_APPLICATION_FIELD_VALUE, payload: newFieldValue });
  } catch (error: any) {
    errorHandling({ error });
  }
};

export const updateTuitionVoucherCodeFieldValue = (
  applicantId: string,
  getBearerToken: () => Promise<string>,
  newFieldValue: INewFieldValue,
) => async (dispatch: Dispatch) => {
  try {
    const url = `/api/admission/applicants/${applicantId}/tuition-fee/coupon`;
    const token = await getBearerToken();
    const response = await fetch(url, {
      method: 'PUT',
      headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
      body: JSON.stringify(newFieldValue),
    });

    if (!response.ok) {
      const body = await response.json();
      const error = humanizeErrorMessage(response, body.Detail);

      throw error;
    }
    dispatch({ type: UPDATE_TUITION_FIELD_VALUE, payload: newFieldValue });
  } catch (error: any) {
    errorHandling({ error });
  }
};
