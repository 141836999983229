import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../../shared/api/adminUI.api';
import {
  personalDetailsKeys,
  PersonalDetailsResponse,
} from '../../../models/LearnerRegistration';
import IError, { ResponseError } from '../../../models/Error';
import { WithLoading } from '../../helper-components/loading.component';
import { WithErrorHandling } from '../../helper-components/error-handling.component';
import { useNationalityOptionsContext } from '../../../shared/providers/nationalityOptionsProvider';

import {
  LearnerRegistrationApplicantBlock, TitleBarWithCta,
} from '../styled.components';
import FieldEditor from '../../admissions/applicant-editable-info/editing-component/editing-component';
import FieldEditorPhone from '../../admissions/applicant-editable-info/editing-component/editing-component-phone';
import { APPLICANT_STATUS } from '../../constants/common-constants';

type RequestStateType = 'initial' | 'loading' | 'success' | 'error';

const defaultErrorMessage = 'There was an error on the personal detail updates';

const getError = (error: ResponseError): IError => {
  const isCountryForbidden = error.PropertyName === 'Country' && error.ErrorCode === 'BlockedCountry';
  const isStateForbidden = error.PropertyName === 'State' && error.ErrorCode === 'BlockedState';

  const getMessage = () => {
    switch (true) {
      case isCountryForbidden:
        return 'We are unable to accept learners who reside in the selected country';
      case isStateForbidden:
        return 'We are unable to accept learners who reside in the selected state';

      default:
        return defaultErrorMessage;
    }
  };

  return { message: getMessage(), ErrorMessage: error.ErrorMessage };
};

export interface PersonalDetailsProp {
  readonly getBearerToken: () => Promise<string>;
  canEditPersonal: boolean;
  setLocation: React.Dispatch<React.SetStateAction<{country: string | null, state: string | null} | undefined>>
}

const PersonalDetailsComponent = (props: PersonalDetailsProp) => {
  const { getBearerToken, canEditPersonal, setLocation } = props;
  const { applicantId } = useParams<{ applicantId: string }>();

  const {
    countryOptions, stateOptions, nationalityOptions,
  } = useNationalityOptionsContext();

  const [personalDetailsState, setPersonalDetailsState] = useState<RequestStateType>('initial');
  const [personalDetails, setPersonalDetails] = useState<PersonalDetailsResponse>();
  const [personalDetailsError, setPersonalDetailsError] = useState<IError | null>(null);

  const [editInProgress, setEditInProgress] = useState(false);
  const [editError, setEditError] = useState<IError>();

  const fetchApplicantPersonalDetails = async (id: string) => {
    try {
      setPersonalDetailsState('loading');
      const response = await api.learnerRegistration.getPersonalDetails(id, getBearerToken);
      if (!response.ok) throw await response;
      if (response.status === 204) {
        setPersonalDetails(undefined);
        setLocation(undefined);
      } else {
        const parsedResponse = (await response.json()) as PersonalDetailsResponse;
        setPersonalDetails(parsedResponse);
        setLocation({ country: parsedResponse.Country, state: parsedResponse.State });
      }
      setPersonalDetailsState('success');
    } catch (err) {
      setPersonalDetailsState('error');
      setPersonalDetailsError(err as IError);
    }
  };

  useEffect(() => {
    const loadInitialPersonalDetails = async (): Promise<void> => {
      if (applicantId && personalDetailsState === 'initial') await fetchApplicantPersonalDetails(applicantId);
    };
    loadInitialPersonalDetails();
  }, [applicantId, personalDetailsState]);

  const submitPersonalDetails = async (newValue: any, changedFieldUrl: string): Promise<void> => {
    setEditError(undefined);

    if (!personalDetails || !applicantId) return;
    setEditInProgress(true);
    const payload = { ...personalDetails, ...newValue };

    try {
      const response = await api.learnerRegistration.updatePersonalDetails(applicantId, payload, getBearerToken);
      const responseData = await response.json();

      if (!response.ok) {
        setEditError(getError(responseData?.[0] as ResponseError));
      } else {
        setPersonalDetails(responseData as PersonalDetailsResponse);
      }

      setEditInProgress(false);
    } catch (err) {
      setEditInProgress(false);
      setEditError(err as IError);
    }
  };

  const PersonalDetailsBlock = () => (
    <LearnerRegistrationApplicantBlock>
      <TitleBarWithCta>
        <h3>Personal Details</h3>
        <div>
          {editInProgress && <span>Updating <FontAwesomeIcon icon={faSpinner} /></span>}
        </div>
      </TitleBarWithCta>
      <WithLoading loading={personalDetailsState === 'initial' || personalDetailsState === 'loading'} loadingText="Loading personal details for applicant">
        <WithErrorHandling small error={personalDetailsError}>
          {!personalDetails && (<p>Applicant hasn&apos;t submitted their personal details yet</p>)}

          {personalDetails && (
          <ul>
            {editError && (
            <li className="status-line status-line--error">
              <span>
                {editError.message
                      || editError.ErrorMessage
                      || defaultErrorMessage}
              </span>
            </li>
            )}
            <li>
              <span>{personalDetailsKeys.FirstName}:</span>
              <FieldEditor
                oldValue={personalDetails.FirstName}
                propertyName="FirstName"
                changedFieldUrl="FirstName"
                fieldType="input"
                maxSymbols={40}
                handleNewValue={submitPersonalDetails}
                applicationStatus={canEditPersonal ? APPLICANT_STATUS.REGISTRATION : APPLICANT_STATUS.REGISTRATION_INITIATED}
              />
            </li>
            <li>
              <span>{personalDetailsKeys.LastName}:</span>
              <FieldEditor
                oldValue={personalDetails.LastName}
                propertyName="LastName"
                changedFieldUrl="LastName"
                fieldType="input"
                maxSymbols={80}
                handleNewValue={submitPersonalDetails}
                applicationStatus={canEditPersonal ? APPLICANT_STATUS.REGISTRATION : APPLICANT_STATUS.REGISTRATION_INITIATED}
              />
            </li>
            <li><span>{personalDetailsKeys.Email}:</span>{personalDetails.Email}</li>
            <li>
              <span>{personalDetailsKeys.PhoneNumber}:</span>
              <FieldEditorPhone
                oldPhoneCode={personalDetails.PhoneCountryCode}
                oldPhoneNumber={personalDetails.PhoneNumber}
                propertyName="PhoneNumber"
                changedFieldUrl="PhoneNumber"
                handleNewValue={submitPersonalDetails}
                applicationStatus={canEditPersonal ? APPLICANT_STATUS.REGISTRATION : APPLICANT_STATUS.REGISTRATION_INITIATED}
              />
            </li>
            <li>
              <span>{personalDetailsKeys.BirthYear}:</span>
              <FieldEditor
                oldValue={personalDetails.BirthYear}
                maxSymbols={4}
                propertyName="BirthYear"
                changedFieldUrl="BirthYear"
                fieldType="number"
                paramsValidation="yearOfBirth"
                handleNewValue={submitPersonalDetails}
                applicationStatus={canEditPersonal ? APPLICANT_STATUS.REGISTRATION : APPLICANT_STATUS.REGISTRATION_INITIATED}
              />
            </li>
            <li>
              <span>{personalDetailsKeys.Nationality}:</span>
              <FieldEditor
                oldValue={personalDetails.Nationality}
                propertyName="Nationality"
                changedFieldUrl="Nationality"
                fieldType="select"
                selectOptions={nationalityOptions}
                handleNewValue={submitPersonalDetails}
                applicationStatus={canEditPersonal ? APPLICANT_STATUS.REGISTRATION : APPLICANT_STATUS.REGISTRATION_INITIATED}
              />
            </li>
            <li>
              <span>{personalDetailsKeys.Country}:</span>
              <FieldEditor
                oldValue={personalDetails.Country}
                selectOptions={countryOptions}
                propertyName="Country"
                changedFieldUrl="Country"
                fieldType="select"
                handleNewValue={submitPersonalDetails}
                applicationStatus={canEditPersonal ? APPLICANT_STATUS.REGISTRATION : APPLICANT_STATUS.REGISTRATION_INITIATED}
              />
            </li>
            {personalDetails.Country === 'US' && (
            <li>
              <span>{personalDetailsKeys.State}:</span>
              <FieldEditor
                oldValue={personalDetails.State}
                selectOptions={stateOptions}
                propertyName="State"
                changedFieldUrl="State"
                fieldType="select"
                handleNewValue={submitPersonalDetails}
                applicationStatus={canEditPersonal ? APPLICANT_STATUS.REGISTRATION : APPLICANT_STATUS.REGISTRATION_INITIATED}
              />
            </li>
            )}
            <li>
              <span>{personalDetailsKeys.City}:</span>
              <FieldEditor
                oldValue={personalDetails.City}
                maxSymbols={75}
                propertyName="City"
                changedFieldUrl="City"
                fieldType="input"
                handleNewValue={submitPersonalDetails}
                applicationStatus={canEditPersonal ? APPLICANT_STATUS.REGISTRATION : APPLICANT_STATUS.REGISTRATION_INITIATED}
              />
            </li>
            <li>
              <span>{personalDetailsKeys.Gender}:</span>
              <FieldEditor
                oldValue={personalDetails.Gender}
                propertyName="Gender"
                changedFieldUrl="Gender"
                fieldType="select"
                handleNewValue={submitPersonalDetails}
                applicationStatus={canEditPersonal ? APPLICANT_STATUS.REGISTRATION : APPLICANT_STATUS.REGISTRATION_INITIATED}
              />
            </li>
            <li><span>{personalDetailsKeys.HowFoundNexford}:</span>{personalDetails.HowFoundNexford}</li>
          </ul>
          )}
        </WithErrorHandling>
      </WithLoading>
    </LearnerRegistrationApplicantBlock>
  );

  return <PersonalDetailsBlock />;
};

export default PersonalDetailsComponent;
