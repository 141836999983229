 
 
import React from 'react';
import { OperationStatus } from '../../../shared/models/operation-status';
import fileUploadService, { UploadFileType } from '../../../shared/uploads/upload-service';
import { INxuFileUploadResult } from '../../../shared/models/file-upload-result';
import { isFileSizeAllowed, isFileTypeAllowed } from '../../learner-files/file-upload-modal/file-validation-functions';
import { ADMISSION_FILE_TYPE } from '../../../shared/api/adminUI.api';
import './file-upload.scss';

export interface FileUploadProps {
  readonly getBearerToken: () => Promise<string>;
  readonly learnerId: string;
  readonly type: UploadFileType;
  readonly fileTypes?: string[];
  readonly disabled?: boolean;
  readonly onFileUploaded?: (uploadResult: INxuFileUploadResult) => void;
  readonly uploadStarted?: () => void;
  readonly children?: React.ReactNode;
}

enum FileValidationError {
  Size,
  Type
}

interface FileUploadState {
  readonly status: OperationStatus | null;
  readonly fileTypes: string[];
  readonly validationErrors: FileValidationError[] | null;
}

export default class AdmissionsFileUpload extends React.Component<FileUploadProps, FileUploadState> {
  constructor(props: FileUploadProps) {
    super(props);
    this.state = {
      status: null,
      fileTypes: props.fileTypes || ['jpg', 'png', 'jpeg', 'pdf', 'docx'],
      validationErrors: null,
    };
  }

  readonly getFileTypesList = (): string => {
    const { fileTypes } = this.state;
    return fileTypes.map((x) => `.${x}`).join(', ');
  };

  readonly getFileValidationErrors = (file: File): FileValidationError[] => {
    const errors: FileValidationError[] = [];
    if (!isFileSizeAllowed(file.size)) {
      errors.push(FileValidationError.Size);
    }
    const fileExtension = file.name.substr(file.name.lastIndexOf('.') + 1);
    if (!isFileTypeAllowed(fileExtension)) {
      errors.push(FileValidationError.Type);
    }
    return errors;
  };

  uploadFailed = () => {
    this.setState({ status: OperationStatus.Error });
  };

  readonly fileChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event && event.target && event.target.files && event.target.files.length) {
      this.uploadFile(event.target.files[0]);
    }
  };

  readonly uploadFile = async (file: File): Promise<void> => {
    const { type, onFileUploaded, getBearerToken, learnerId, uploadStarted } = this.props;
    this.setState({ status: null });
    const validationErrors = this.getFileValidationErrors(file);
    if (validationErrors.length > 0) {
      this.setState({
        status: null,
        validationErrors,
      });
      return;
    }
    try {
      this.setState({
        status: OperationStatus.InProgress,
        validationErrors: null,
      });
      if (uploadStarted) {
        uploadStarted();
      }
      const token = await getBearerToken();
      const result = await fileUploadService.uploadFileByFileType(token, learnerId, ADMISSION_FILE_TYPE, type, file);
      this.setState({
        status: OperationStatus.Success,
      });
      if (onFileUploaded) {
        onFileUploaded({
          originalFileName: file.name,
          uploadedFileName: result.fileName,
          containerName: result.fileName,
        });
      }
    } catch {
      this.setState({ status: OperationStatus.Error });
    }
  };

  render() {
    const { disabled, children, type } = this.props;
    const { status, validationErrors } = this.state;
    return (
      <label className="wrapper" htmlFor={`fileuploader${type}`}>
        <input id={`fileuploader${type}`} type="file" style={{ display: 'none' }} disabled={disabled} onChange={(e) => this.fileChanged(e)} />
        <span className="uploader-text">{children}</span>
        {status === OperationStatus.Error && (<span>Error {validationErrors}</span>)}
      </label>
    );
  }
}
