import { ProductItem, RegistrationItem } from '../../models/LearnerRegistration';
import { appFetch } from './fetch';

export const getProductList = async (getBearerToken: () => Promise<string>) => {
  const token = await getBearerToken();

  return appFetch<ProductItem[]>('/api/requirements/versions/latest/products', {
    method: 'GET',
    headers: [['Authorization', `Bearer ${token}`]],
  });
};

export type PartnerProduct = {
  code: string;
  type: string;
  level: string;
};

export const getPartnerProductList = async (
  partnerId: string,
  learnerId: string,
  getBearerToken: () => Promise<string>
) => {
  const token = await getBearerToken();

  return appFetch<PartnerProduct[]>(
    `/api/adminaggregator/v1/partners/${partnerId}/learners/${learnerId}/available-products`,
    {
      method: 'GET',
      headers: [['Authorization', `Bearer ${token}`]],
    }
  );
};

export const getActiveRegistrations = async (
  applicantId: string,
  getBearerToken: () => Promise<string>
) => {
  const token = await getBearerToken();

  return appFetch<RegistrationItem[]>(`/api/registration/learners/${applicantId}/registrations`, {
    method: 'GET',
    headers: [['Authorization', `Bearer ${token}`]],
  });
};
