 
import React from 'react';
import { Row } from 'react-bootstrap';
import GradeGPA from '../../learner-grades/components/grade-gpa';
import { IEvent, IEventDetails, ICustomEventProps } from '../learner-history-model';
import { PropContainer, PropName, PropValue } from './utils/event-prop';

const sapParameterMap : { [key: string]: string } = {
  pace: 'Pace',
  cgpa: 'CGPA',
  recentgpa: 'Recent GPA',
  academicwarning: 'Academic Warning',
  academicstanding: 'Academic Standing',
};

export const SAPLearnerSAPUpdatedEventSelector: (event:IEvent) => IEventDetails = (event:IEvent) => ({
  metadata: {
    type: {
      category: 'Academic Standing',
      eventName: '',
    },
    date: event.updatedDateTime,
  },
  element: <Event event={event} />,
});

const Event: React.FunctionComponent<ICustomEventProps> = (props: ICustomEventProps) => {
  const { event } = props;

  return (
    <Row>
      {(event.sAPParametersStatus || []).map((s:any) => (
        <PropContainer key={s.parameterCode}>
          <PropName>{sapParameterMap[s.parameterCode] || s.parameterCode}:</PropName>
          <PropValue primary={s.parameterCode === 'academicstanding'}>
            {s.parameterCode.includes('gpa') ? <GradeGPA gpa={s.statusText} /> : s.statusText}
          </PropValue>
        </PropContainer>
      ))}
    </Row>
  );
};
