import React, { useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { UpdateFXRateContainer, SmallTextContainer } from './styled.components';
import AuthProps from '../auth/auth-props';
import { WithErrorHandling } from '../helper-components/error-handling.component';
import api from '../../shared/api/adminUI.api';
import IError from '../../models/Error';
import { WithPermissions } from '../helper-components/view-permission.component';
import { Breadcrumb } from '../breadcrumb/breadcrumb';
import { Table } from '../ui/table/table';

interface IFXRateHistoryItem {
  rateId: string;
  fxRate: number;
  effectiveFrom: string;
  effectiveUntil: string | null;
}

interface IFXRatePermission {
  FxRateEditor: boolean;
}

const FXRatePage = (props: AuthProps) => {
  const { getBearerToken } = props;
  const { currency } = useParams() as { currency: string };

  const [rates, setRates] = useState<Array<IFXRateHistoryItem>>(
    [] as IFXRateHistoryItem[]
  );
  const [newRate, setNewRate] = useState('');
  const [newDate, setDate] = useState<Date | null>();
  const [actualRate, setActualRate] = useState<IFXRateHistoryItem | null>();
  const [status, setStatus] = useState<
    'initial' | 'loading' | 'success' | 'error'
  >('initial');

  const [updateResponseError, setUpdateResponseError] = useState<IError | null>(
    null
  );
  const [validationError, setValidationError] = useState<string | null>();
  const [permission, setPermission] = useState<boolean>(false);

  const getPermission = async () => {
    try {
      const response = await api.admissions.getFXRatePermission(getBearerToken);
      const perm = (await response.json()) as IFXRatePermission;

      setPermission(perm.FxRateEditor);
    } catch (err) {
      setStatus('error');
      setPermission(false);
    }
  };

  const fetchHistory = async () => {
    try {
      setStatus('loading');
      const response = await api.fxRates.getRateWithHistory(
        currency,
        getBearerToken
      );
      if (!response.ok) throw await response;

      const newRates = (await response.json()) as Array<IFXRateHistoryItem>;

      setRates(newRates);
      setStatus('success');
      if (newRates.length > 0) {
        const now = new Date();
        now.setHours(0, 0, 0, 0);

        const actualRateItem = newRates.find((item) => {
          const effectiveFromDate = new Date(item.effectiveFrom);
          const effectiveUntilDate =
            item.effectiveUntil === null ? null : new Date(item.effectiveUntil);

          return (
            effectiveFromDate < now &&
            (effectiveUntilDate === null || effectiveUntilDate > now)
          );
        });

        if (actualRateItem != null) {
          setActualRate(actualRateItem);
        }

        setNewRate(newRates[0].fxRate.toString());
      }
    } catch (err) {
      setStatus('error');
    }
  };

  useEffect(() => {
    const defaultDatePickerValue = new Date();
    defaultDatePickerValue.setHours(0, 0, 0, 0);
    setDate(defaultDatePickerValue);

    getPermission();
    fetchHistory();
  }, []);

  const updateRate = async () => {
    const newRateNumber = Number(newRate);
    const latestRateNumber = Number(actualRate?.fxRate);
    setValidationError(null);

    if (newRateNumber <= 0) {
      setValidationError('The new rate should be a positive number');
      return;
    }

    if (newRateNumber === latestRateNumber) {
      setValidationError('The rate is the same as the latest rate');
      return;
    }

    const diff = Math.abs(
      (newRateNumber - latestRateNumber) /
        ((newRateNumber + latestRateNumber) / 2)
    );
    if (diff > 0.2) {
      setValidationError(
        'The rate change is too high. The difference should not exceed 20%'
      );
      return;
    }

    if (
      rates.find((item) => {
        const effectiveFromDate = new Date(item.effectiveFrom);
        return effectiveFromDate === newDate;
      })
    ) {
      setValidationError('The rate for this date already exists');
      return;
    }

    try {
      const response = await api.fxRates.updateFXRate(
        currency,
        Number(newRate),
        moment(newDate).format('yyyy-MM-DD'),
        getBearerToken
      );

      if (!response.ok) throw await response;
      fetchHistory();
    } catch (err) {
      setUpdateResponseError(err as IError);
    }
  };

  return (
    <>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item to='/'>Home</Breadcrumb.Item>
          <Breadcrumb.Item to='/fxrates'>FX Rates</Breadcrumb.Item>
          <Breadcrumb.Item active>{currency}</Breadcrumb.Item>
        </Breadcrumb>
        <WithPermissions permission={permission} noAccesText=''>
          <UpdateFXRateContainer>
            <h4>
              <b>Edit FX rate: {currency}</b>
            </h4>
            <SmallTextContainer>
              Current Rate: {actualRate?.fxRate} per $1.00 | Effective from:{' '}
              {moment(actualRate?.effectiveFrom).format('YYYY-MM-DD')}
            </SmallTextContainer>
            <div>
              <span>Effective Date</span>
              <DatePicker
                wrapperClassName='datePicker'
                minDate={new Date()}
                selected={newDate}
                dateFormat='yyyy-MM-dd'
                onChange={(date: Date | null) => setDate(date)}
              />
            </div>
            <div>
              <span>FX Rate (per $1.00)</span>
              <input
                type='number'
                defaultValue={newRate}
                onChange={(e) => {
                  setNewRate(e.target.value);
                }}
              />
            </div>
            <div>
              <Button onClick={() => updateRate()}>Save</Button>
              <span className='error_lable'>{validationError}</span>
            </div>
            <WithErrorHandling error={updateResponseError} />
          </UpdateFXRateContainer>
        </WithPermissions>
        <Table
          loading={status === 'initial' || status === 'loading'}
          data={rates}
          columns={[
            {
              header: 'Effective From',
              accessorKey: 'effectiveFrom',
              cell: ({ cell }) => moment(cell.getValue()).format('YYYY-MM-DD'),
            },
            {
              header: 'End Date',
              accessorKey: 'effectiveUntil',
              cell: ({ cell }) =>
                cell.getValue() === null
                  ? ''
                  : moment(cell.getValue()).format('YYYY-MM-DD'),
            },
            {
              header: 'FX Rate (per $1.00)',
              accessorKey: 'fxRate',
            },
          ]}
        />
      </Container>
    </>
  );
};

export default FXRatePage;
