 
import React from 'react';
import { Row } from 'react-bootstrap';
import { IEvent, IEventDetails, ICustomEventProps } from '../learner-history-model';
import EventProp from './utils/event-prop';
import EventPropComment from './utils/event-prop-comment';
import EventPropProduct from './utils/event-prop-product';
import EventPropProductReEnrollment from './utils/event-prop-product-re-enrollment';
import EventPropStartDate from './utils/event-prop-start-date';

export const programLearnerEnrolledToProgramEventSelector: (event:IEvent) => IEventDetails = (event:IEvent) => ({
  metadata: {
    type: {
      category: 'Program Enrollment',
      eventName: 'New Program Enrollment',
    },
    date: event.updatedDateTime,
    editor: event.editor,
  },
  element: <Event event={event} />,
});

const Event: React.FunctionComponent<ICustomEventProps> = (props: ICustomEventProps) => {
  const { event } = props;

  const isProgramTheSame = () => event.productCode === event.oldProductCode && event.productType === event.oldProductType;

  return (
    <Row>
      {
        isProgramTheSame()
          ? <EventPropProductReEnrollment productType={event.productType} productCode={event.productCode} />
          : (
            <>
              <EventPropProduct name="New Product:" productType={event.productType} productCode={event.productCode} primary />
              <EventPropProduct name="Old Product:" productType={event.oldProductType} productCode={event.oldProductCode} />
            </>
          )
       }

      <EventProp name="Foundations Courses:" value={event.foundationCoursesCsv} />
      <EventPropStartDate name="Start Date:" value={event.startDate} />
      <EventPropComment value={event.comment} />
    </Row>
  );
};
