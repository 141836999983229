import React, { useState } from 'react';
import {
  Table, Modal, Spinner, Button, Badge, Row, Container,
} from 'react-bootstrap';
import {
  StyledAchievementsContainer, StyledAchievementsWrapper, WaitingBlock, StyledModalButtons,
} from './achievements-styled-component';
import { ILearnerAchievementDetails, ILearnerAchievementProgress } from '../../shared/actions/learner-profile.action';
import errorHandling from '../helper-components/alert-component.component';
import AchievementRow from './achievement-row';
import getQueryVariable from '../helper-components/get-query-variable';

type IAchievementsProps = {
  canApproveAchievements: boolean
  onUpdateLearnerAchievementStatus: (
    token: () => Promise<string>,
    achievementId: string,
  ) => void;
  infoAchievements?: ILearnerAchievementDetails | null;
  getBearerToken: () => Promise<string>;
  learnerId: string;
  updateStatusPending: boolean;
};

export interface IDialog {
  achievementId: string;
  modalVisibility?: any;
  getBearerToken: () => Promise<string>;
  handleClose: () => void;
  onStatusChange: (
    token: () => Promise<string>,
    achievementId: string,
  ) => void;
  updateStatusPending: boolean

}

const Dialog: React.FunctionComponent<IDialog> = (
  props: IDialog,
) => {
  const {
    modalVisibility,
    handleClose,
    getBearerToken,
    achievementId,
    onStatusChange,
    updateStatusPending,
  } = props;

  const updateStatus = async (): Promise<void> => {
    try {
      const response = await onStatusChange(getBearerToken, achievementId);
       
      if (response === undefined) {
        setTimeout(handleClose, 3000);
      }
    } catch (error: any) {
      errorHandling({ error });
    }
  };
  return (
    <Modal id="modal" show={modalVisibility} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <h5 className="text-center"> Are you sure that you want to confer this credential?</h5>
      </Modal.Header>
      <Modal.Body>
        <StyledModalButtons>
          <Button
            onClick={updateStatus}
            variant="success">
            {updateStatusPending ? <Spinner size="sm" animation="border" /> : 'Confer'}
          </Button>
          <Button
            onClick={() => {
              handleClose();
            }}
            variant="secondary">No</Button>
        </StyledModalButtons>
      </Modal.Body>
    </Modal>
  );
};

function isProgressImportant(p: ILearnerAchievementProgress, subscriptionCode: string, subscriptionProductType: string | undefined): boolean {
  if (!subscriptionCode) {
    return false;
  }

  if (p.achievementType !== 'credential') {
    return false;
  }

  const selfImportanceMap: { [key: string]: (p: ILearnerAchievementProgress) => boolean } = {
    Degree: (a: ILearnerAchievementProgress) => a.passedCoursesCount >= 1,
    Certificate: (a: ILearnerAchievementProgress) => !!a.requiredCoursesCount && (a.requiredCoursesCount - a.passedCoursesCount) <= 2,
    Course: () => true,
  };

  if (p.code === subscriptionCode) {
    return selfImportanceMap[p.productType](p);
  }

  if (subscriptionProductType === 'Degree') {
    return false;
  }

  return p.productType === 'Degree'
    ? p.progress >= 80
    : false;
}

const achievementSortMap: { [key: string]: number } = {
  Degree: 100,
  Certificate: 200,
  Course: 300,
};
const productTypeValue = (productType:string): number => (achievementSortMap[productType] || 0);

export const Achievements: React.FC<IAchievementsProps> = ({
  canApproveAchievements, onUpdateLearnerAchievementStatus, infoAchievements, learnerId, getBearerToken, updateStatusPending,
}: IAchievementsProps) => {
  const [achievementIdModal, setAchievementIdModal] = useState<string | null>(null);
  const [modalVisibility, setModalVisibility] = useState<boolean | null | undefined>(false);

  const openModalWithId = (value:string) => {
    setAchievementIdModal(value);
    setModalVisibility(true);
  };
  const closeModalWithId = () => {
    setAchievementIdModal('');
    setModalVisibility(false);
  };

  if (!infoAchievements) {
    return <WaitingBlock> ...loading </WaitingBlock>;
  }

  const { achievements, progress, subscriptionCode } = infoAchievements;
  const sortedAchievements = achievements && [...achievements].sort((lhs, rhs) => {
    if (lhs.productType === rhs.productType) {
      return (new Date(rhs.date || 0)).valueOf() - (new Date(lhs.date || 0).valueOf());
    }

    return productTypeValue(lhs.productType) - productTypeValue(rhs.productType);
  });

  const credentials = sortedAchievements && sortedAchievements.filter((a) => a.achievementType === 'credential');
  const nonCredentials = sortedAchievements && sortedAchievements.filter((a) => a.achievementType !== 'credential');
  const subscriptionProgress = progress && progress.find((p) => p.code === subscriptionCode);
  const importantProgress = (progress && progress.filter((p) => isProgressImportant(p, subscriptionCode,
    subscriptionProgress && subscriptionProgress.productType))) || [];

  const activeAchievementId = getQueryVariable('achievementId');
  // @ts-ignore
  return (<StyledAchievementsContainer>
    <StyledAchievementsWrapper>
      <h3>Credentials</h3>
      {
        credentials ? ((credentials.length === 0 && importantProgress.length === 0) ? (<>No credentials yet</>) : (
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Category</th>
                <th>Category Code</th>
                <th>Credential Type</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              { credentials.map((value) => (
                <tr key={value.id} style={activeAchievementId === value.id ? { background: 'yellow' } : {}}>
                  <AchievementRow
                    learnerId={learnerId}
                    category={value.category}
                    name={value.name}
                    productType={value.productType}
                    productCode={value.productCode}
                    date={value.date}
                    status={value.status}
                    approvedBy={value.approvedBy}
                    approvalDate={value.approvalDate}
                    achievementType={value.achievementType}
                    canApproveAchievements={canApproveAchievements}
                    artifacts={value.artifacts}
                    openModal={() => openModalWithId(value.id)}
                    getBearerToken={getBearerToken}
                  />
                </tr>))
              }
              { importantProgress.map((value) => (
                <tr key={value.code}>
                  <td>{value.name}</td>
                  <td>{value.productCode}</td>
                  <td>{value.productType}</td>
                  <td>{' '}</td>
                  <td>
                    <Container>
                      <Row>
                        <Badge pill bg="secondary">In Progress: {value.progress}%</Badge>
                      </Row>
                      <Row>
                        {value.passedCoursesCount} out of {value.requiredCoursesCount} courses completed
                      </Row>
                    </Container>
                  </td>
                </tr>))
              }

              <Dialog
                getBearerToken={getBearerToken}
                handleClose={closeModalWithId}
                achievementId={achievementIdModal as string}
                onStatusChange={onUpdateLearnerAchievementStatus}
                modalVisibility={modalVisibility}
                updateStatusPending={updateStatusPending}
              />
            </tbody>
          </Table>
        ))
          : (
            <WaitingBlock> ...Credentials table is loading </WaitingBlock>
          )
      }

      <h3>Other Achievements</h3>
      {
        achievements ? (achievements.length === 0 ? (<>No achievements yet</>) : (
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Category</th>
                <th>Category Code</th>
                <th>Credential Type</th>
                <th>Date</th>
                <th>Artifacts</th>
              </tr>
            </thead>
            <tbody>
              {
                nonCredentials.map((value) => (
                  <tr key={value.id} style={activeAchievementId === value.id ? { background: 'yellow' } : {}}>
                    <AchievementRow
                      name={value.name}
                      category={value.category}
                      productType={value.productType}
                      productCode={value.productCode}
                      date={value.date}
                      status={value.status}
                      achievementType={value.achievementType}
                      artifacts={value.artifacts}
                      learnerId={learnerId}
                      getBearerToken={getBearerToken}
                    />
                  </tr>
                ))
              }
            </tbody>
          </Table>
        ))
          : (
            <WaitingBlock> ...Achievements table is loading </WaitingBlock>
          )
      }

    </StyledAchievementsWrapper>
  </StyledAchievementsContainer>);
};

export default Achievements;
