import React from 'react';
import { useMemo } from 'react';
import { useProducts } from './use-products';
import { useField } from 'react-final-form';
import {
  PRODUCT_CODE,
  PRODUCT_TYPE,
} from '../../admissions/admissions-form/admissions-form.constants';
import { Select } from '../../ui/select/select';
import styles from './approve-admission-decision-form.module.scss';
import { composeValidators, required } from '../../../utils/validate.utils';
import { emptySelectOption } from '../../helper-components/form-components/form-filed-components/form-filed.components';

type SelectItem = {
  label: string;
  value: string;
};

export const ProductCodeSelect = () => {
  const { data: products, isLoading } = useProducts();
  const { input: productTypeInput } = useField<{
    label: string;
    value: string;
  }>(PRODUCT_TYPE);

  const productCodes = useMemo(() => {
    const productType = productTypeInput.value?.value;

    if (!productType) return [];

    return products
      .filter((product) => product.type === productType)
      .map((product) => ({
        label: product.code,
        value: product.code,
      }));
  }, [products, productTypeInput]);

  return (
    <Select
      name={PRODUCT_CODE}
      options={productCodes}
      label='Product Code'
      isLoading={isLoading}
      className={styles.element}
      fieldProps={{
        validate: composeValidators(required, (value: SelectItem) => {
          if (value.value === emptySelectOption.value) return 'Required';

          return undefined
        }),
      }}
    />
  );
};
