 
 
 
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import {
  WorQDiv,
  WorQHeader,
} from './styled-components';
import WorQIFrame from './components/worq-iframe';
import WorQHtml from './components/worq-html';
import AuthProps from '../auth/auth-props';
import errorHandling from '../helper-components/alert-component.component';


interface WorQTaskResult {
  readonly TaskId: string;
  readonly UiUrl: string | null;
  readonly UiHtmlSnippet: string | null;
  readonly WorQTaskSessionId: string;
}

interface WorQState {
  status: 'Loading' | 'Errored' | 'Loaded';
  signedIn: boolean;
  checkingForWorQ: boolean;
  task: WorQTaskResult | null;
  nothingTodo: boolean;
  submitting: boolean;
  getNextTask: boolean;
}

interface TaskCompletedReq {
  readonly worQTaskSessionId: string;
  readonly origin: string | null;
  readonly stop: boolean;
  readonly answer: any;
  readonly answerText: string | null;
}

export default class WorQ extends React.PureComponent<AuthProps, WorQState> {
  constructor(props: AuthProps) {
    super(props);

    this.state = {
      status: 'Loaded',
      signedIn: false,
      task: null,
      nothingTodo: false,
      submitting: false,
      checkingForWorQ: false,
      getNextTask: true,
    };

    this.signIn = this.signIn.bind(this);
    this.answered = this.answered.bind(this);
  }

  private async signIn() : Promise<void> {
    this.setState({
      task: null,
      nothingTodo: false,
      checkingForWorQ: true,
      getNextTask: true,
    });

    try {
      const { getBearerToken } = this.props;
      const token = await getBearerToken();
      const response = await fetch('/api/worq/tasks', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (response.ok) {
        const task = await response.json() as WorQTaskResult;
        this.handleNewTask(task, false);
      } else {
        throw await response;
      }
    } catch (error: any) {
      errorHandling({ error });
    }
  }

  private handleNewTask(task : WorQTaskResult | null, stop: boolean) : void {
    if (task && task.TaskId) {
      this.setState({
        task, signedIn: true, nothingTodo: false, checkingForWorQ: false,
      });
    } else if (stop) {
      this.setState({
        task: null, signedIn: false, nothingTodo: false, checkingForWorQ: false,
      });
    } else {
      this.setState({
        task: null, signedIn: false, nothingTodo: true, checkingForWorQ: false,
      });
    }
  }

  private async answered(answer:any, origin: string | null, answerText: string | null) : Promise<void> {
    const { task, getNextTask } = this.state;
    const stop = !getNextTask;

    if (!task) {
      throw new Error("Can't answer question because the task isn't in the state");
    }

    this.setState({ submitting: true });

    const result : TaskCompletedReq = {
      worQTaskSessionId: task.WorQTaskSessionId,
      origin,
      answer,
      answerText,
      stop,
    };

    try {
      const { getBearerToken } = this.props;
      const token = await getBearerToken();
      const response = await fetch(`/api/worq/tasks/${task.TaskId}/answer`, {
        method: 'POST',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify(result),
      });
      if (response.ok) {
        const newTask = await response.json() as WorQTaskResult;
        this.handleNewTask(newTask, stop);
      } else {
        throw await response;
      }
    } catch (error: any) {
      errorHandling({ error });
    }

    this.setState({ submitting: false });
  }

  private renderAsHtml(html:string) {
    return (<WorQHtml html={html} answer={this.answered} />);
  }

  private renderAsIFrame(url:string) {
    const { getBearerToken } = this.props;

    return (<WorQIFrame uiUrl={url} answer={this.answered} getBearerToken={getBearerToken} />);
  }

  private renderGetWorQ(checkingForWorQ:boolean) {
    if (checkingForWorQ) {
      return (
        <Button disabled={checkingForWorQ}>
          <Spinner animation="border" size="sm" />
          <span> Checking...</span>
        </Button>
      );
    }

    return (<Button onClick={this.signIn}>Get some work</Button>);
  }

  private static renderNothingToDo() {
    return (
      <p>
        There is nothing to do right now. Have a cup of tea and then press
        Get some work to check for more work.
      </p>
    );
  }

  private static renderId(id:string) {
    return (<span style={{
      color: 'silver',
      fontSize: 'small',
      width: 600,
      wordWrap: 'break-word',
      display: 'inline-block',
    }}>id: {window.btoa(id)}</span>);
  }

  private renderTaskFlow() {
    const { getNextTask } = this.state;
    return (
      <div>
        <label className="checkbox" htmlFor="getNextTask">
          <input
            id="getNextTask"
            type="checkbox"
            checked={getNextTask}
            onChange={() => this.setState({ getNextTask: !getNextTask })}
          />
          <span>Automatically get more WorQ</span>
        </label>
      </div>
    );
  }

  render(): JSX.Element {
    const {
      status,
      signedIn,
      task,
      nothingTodo,
      submitting,
      checkingForWorQ,
    } = this.state;

    return (
      <WorQDiv>
        <WorQHeader>WorQ</WorQHeader>
        {status === 'Loading' && <Spinner animation="border" size="sm" />}
        {!signedIn && this.renderGetWorQ(checkingForWorQ)}
        {!submitting && !!task && this.renderTaskFlow()}
        {!submitting && !!task && !!task.UiUrl && this.renderAsIFrame(task.UiUrl)}
        {!submitting && !!task && !!task.UiHtmlSnippet && this.renderAsHtml(task.UiHtmlSnippet)}
        {!submitting && !!task && WorQ.renderId(task.TaskId)}
        {submitting && <div>Submitting...</div>}
        {nothingTodo && WorQ.renderNothingToDo()}
      </WorQDiv>
    );
  }
}
