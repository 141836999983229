 
import * as React from 'react';
import {
  Col, Row, Card, ListGroup,
} from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import {
  StylesForCollapsePanel,
  StylesForCheckBox,
  CourseRow,
} from './styled-component';

interface ICourseListProps {
  courseData: any;
  checkedItems: Map<string, boolean>;
  learnerProductCode: string;
  handleChange: (e: React.BaseSyntheticEvent) => void;
  specialisations?: boolean;
}

const CourseList: React.FunctionComponent<ICourseListProps> = (props: ICourseListProps) => {
  const {
    courseData, specialisations, handleChange, checkedItems,
    learnerProductCode,
  } = props;
  return (
    <>
      {courseData.map((course: any) => (
        <CourseRow key={course.id}>
          <Col xs={9}>
            {specialisations ? (
              <StylesForCollapsePanel>
                <Collapsible trigger={`${course.name}`}>
                  <Card>
                    <Card.Body>
                      <ListGroup>
                        {course.courses.map((subCourse: any) => (
                          <ListGroup.Item key={subCourse.id}>
                            {`${subCourse.id} ${subCourse.name}`}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </Collapsible>
              </StylesForCollapsePanel>
            ) : (
              <Row>
                {`${course.course.id} ${course.course.name}`}
                {' '}
              </Row>
            )}
          </Col>
          <Col xs={3}>
            {specialisations ? (
              <StylesForCheckBox isReadOnly={false}>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name={course.id}
                    checked={checkedItems.get(course.id)}
                    onChange={handleChange}
                  />
                  <span>Choose specialisation</span>
                </label>
              </StylesForCheckBox>
            ) : (
              <StylesForCheckBox
                isReadOnly={
                  course.isPassed
                  || learnerProductCode === course.course.id
                }
              >
                <label className="checkbox">
                  <input
                    type="checkbox"
                    disabled={course.isPassed || learnerProductCode === course.course.id}
                    name={course.course.id}
                    checked={checkedItems.get(course.course.id)}
                    onChange={handleChange}
                  />
                  <span>Choose elective</span>
                </label>
              </StylesForCheckBox>
            )}
          </Col>
        </CourseRow>
      ))}
    </>
  );
};
export default CourseList;
