import React from 'react';
import { Spinner, Row, Col } from 'react-bootstrap';
import { LoadingP, StyledLoadingText } from './styled-components';

interface WithLoadingProps {
  loading: boolean;
  spinner?: boolean;
  loadingText: string;
}

const withLoading =
  <P extends object>(
    Component: React.ComponentType<P>
  ): React.FC<P & WithLoadingProps> =>
  ({ loading, spinner, loadingText, ...props }: WithLoadingProps) =>
    loading ? (
      spinner ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Row>
            <div style={{ width: 'max-content' }}>
              <Spinner animation='border' size='sm' />
            </div>
            <Col>
              <StyledLoadingText>{loadingText}</StyledLoadingText>
            </Col>
          </Row>
        </div>
      ) : (
        <>
          <LoadingP>{loadingText}</LoadingP>
        </>
      )
    ) : (
      <Component {...(props as P)} />
    );
export default withLoading;

export const WithLoading = withLoading(
  ({ children }: { children: React.ReactNode }) => <>{children}</>
);
