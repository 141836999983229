import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createColumnHelper, Row } from '@tanstack/react-table';
import SearchResponse, { SearchLearner } from '../models/SearchResponse';
import { IRootState } from '../../../shared/reducers';
import { Table } from '../../ui/table/table';

interface LearnerSearchTableProps extends StateProps {
  alterPage: any;
  currentPage: number;
}

function LearnerSearchResultsTable(props: LearnerSearchTableProps) {
  const {
    searchResponse,
    resultStatus,
    alterPage,
    currentPage,
  } = props;
  const pageCount = Math.ceil(searchResponse.Count / 20);
  const data = searchResponse.Results ? searchResponse.Results : [];
  const columnHelper = createColumnHelper<SearchLearner>();

  switch (resultStatus) {
    case 'Empty':
      return (
        <> </>
      );
    default:
      return (
        <Table
          data={data}
          columns={[
            columnHelper.accessor((row: SearchLearner) => row.Id, {
              id: 'ID',
              minSize: 200,
            }),
            columnHelper.accessor((row: SearchLearner) => row.FullName, {
              id: 'Full Name',
              minSize: 300,
            }),
            columnHelper.accessor((row: SearchLearner) => row.Email, {
              id: 'Email',
              minSize: 300,
            }),
            columnHelper.accessor((row: SearchLearner) => row.SuccessAdvisorFullName, {
              id: 'Success Advisor',
              minSize: 200,
            }),
            columnHelper.accessor((row: SearchLearner) => row.Id, {
              id: ' ',
              minSize: 100,
              cell: ({ row }) => <Link to={`/learners/${row.original.Id}`}>View</Link>,
            }),
          ]}
          pagination={{
            currentPage,
            pageCount,
            onPageChange: alterPage,
            pageSize: 20,
          }}
          loading={resultStatus === 'Loading'}
        />
      );
  }
}

const mapStateToProps = ({ learnerSearchState }: IRootState) => ({
  resultStatus: learnerSearchState.resultStatus,
  searchResponse: learnerSearchState.searchResponse,
});

interface StateProps {
  resultStatus: 'Empty' | 'Loading'| 'Errored' | 'Loaded';
  searchResponse: SearchResponse;
}

export default connect(mapStateToProps)(LearnerSearchResultsTable);
