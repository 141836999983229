 
 
import React from 'react';
import IError from '../../models/Error';

interface WithErrorHandlingProps {
  error: IError | null;
  small?: boolean;
}
const ERROR = 'Error!';

const withErrorHandling = <P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P & WithErrorHandlingProps> => ({
    error,
    small,
    ...props
  }: WithErrorHandlingProps) => {
    const errorMessage = error
      ? error.Message || error.statusText || error.message || error.ErrorMessage || 'Error on request'
      : '';
    return error
      ? (
        small ? (
          <span>
            {' '}
            {errorMessage}
          </span>
        ) : (
          <>
            <h3>{ERROR}</h3>
            <pre>
              { `${error.statusCode || error.status}: ${errorMessage}`}
            </pre>
          </>
        )
      ) : <Component {...props as P} />;
  };
export default withErrorHandling;

export const WithErrorHandling = withErrorHandling(({ children }: { children?: React.ReactNode }) => <>{children}</>);
