
import styled from 'styled-components';

export const LoadingP = styled.h6`
  padding: 30px 0;
  text-align: center;
  font-size: 13px;
`;

export const StyledLoadingText = styled.span`
  text-align: center;
  font-size: 16px;
  padding-left: 10px;
  color: #383737;
`;
