import React from 'react';
import { flexRender, type Row as RowProps } from '@tanstack/react-table';
import styles from './body.module.scss';
import { useTableContext } from '../context';
import { TableStatusIndicator } from './status-indicator/status-indicator';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

const Row = ({ row }: { row: RowProps<any> }) => {
  const rowIndex = (row.depth > 0 ? row.getParentRow()?.index : row.index) ?? 0;

  return (
    <tr className={rowIndex % 2 === 0 ? styles.even : styles.odd}>
      {row.getVisibleCells().map((cell, index) => (
        <td key={cell.id}>
          <div className='d-flex gap-2'>
            {row.getCanExpand() && index === 0 && (
              <div
                role='button'
                tabIndex={0}
                onClick={row.getToggleExpandedHandler()}
              >
                {row.getIsExpanded() ? <FaChevronDown /> : <FaChevronRight />}
              </div>
            )}
            {(!row.depth || !!index) &&
              flexRender(cell.column.columnDef.cell, cell.getContext())}
          </div>
        </td>
      ))}
    </tr>
  );
};

const TableFill = () => {
  const { getRowModel, pagination, getAllColumns } = useTableContext();
  const columns = getAllColumns().length;
  const size = Math.max(pagination?.pageSize ?? 0, 1);

  const rows = getRowModel().rows;
  const remaining = Math.max(size - rows.length, 0);

  if (!remaining) return null;

  const lastRow = rows[rows.length - 1];

  const lastIndex = lastRow
    ? lastRow.depth > 0
      ? lastRow.getParentRow()?.index ?? 0
      : lastRow.index + 1
    : 0;

  return Array.from({ length: remaining }).map((_, index) => {
    return (
      <tr
        key={index}
        className={(lastIndex + index) % 2 === 0 ? styles.even : styles.odd}
      >
        <td colSpan={columns} />
      </tr>
    );
  });
};

export const TableBody = () => {
  const { getRowModel } = useTableContext();

  return (
    <tbody className={styles.tbody}>
      {getRowModel().rows.map((row, index) => (
        <Row key={index} row={row} />
      ))}
      <TableFill />
      <TableStatusIndicator />
    </tbody>
  );
};
