import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { ButtonRow, ButtonRowWrapper } from '../styled-components';
import config from '../../../config.json';

const LearnerExternalRecords: React.FC<{
  canUseRegistration: boolean;
  learnerApplicantInfoPending: boolean;
}> = ({ ...props }) => {
  const [urlConfig] = useState(config.urls);
  const [applicationUrl, setApplicationUrl] = useState(
    props.canUseRegistration ? '/learner-registration' : '/applicants'
  );
  const { learnerId } = useParams<{ learnerId: string }>();

  useEffect(() => {
    if (!props.learnerApplicantInfoPending) {
      setApplicationUrl(
        props.canUseRegistration ? '/learner-registration' : '/applicants'
      );
    }
  }, [props.canUseRegistration, props.learnerApplicantInfoPending]);

  return (
    <>
      <ButtonRowWrapper>
        <ButtonRow>
          <Button
            as='a'
            variant='success'
            href={`${applicationUrl}/${learnerId}`}
            target='_blank'
          >
            Application
          </Button>
        </ButtonRow>
        <ButtonRow>
          <Button
            as='a'
            variant='success'
            href={`${urlConfig.chargebee}/customers?Customers.search=${learnerId}`}
            target='_blank'
          >
            Payment Record
          </Button>
        </ButtonRow>
      </ButtonRowWrapper>
    </>
  );
};

export default LearnerExternalRecords;
