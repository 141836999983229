import {
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  TableOptions,
  TableState,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';

export type UseTableOptions<D> = Omit<TableOptions<D>, 'getCoreRowModel'> & {
  expandable?: boolean;
};

export const useTable = <D>({
  columns,
  expandable,
  data,
  ...options
}: UseTableOptions<D>) => {
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const state = useMemo<Partial<TableState>>(() => {
    const newState: Partial<TableState> = {};

    if (expandable) {
      newState.expanded = expanded;
    }

    return newState;
  }, [expandable, expanded]);

  return useReactTable<D>({
    columns,
    data,
    state,
    getCoreRowModel: getCoreRowModel(),
    ...(expandable && {
      onExpandedChange: setExpanded,
      getSubRows: (row) => (row as any).subRows,
      getExpandedRowModel: getExpandedRowModel(),
    }),
    ...options,
  });
};
