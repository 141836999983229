import React from 'react';
import { flexRender } from '@tanstack/react-table';
import { useTableContext } from './context';

export const TableHeader = () => {
  const { getHeaderGroups } = useTableContext();

  return (
    <thead>
      {getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <th key={header.id}>
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};
