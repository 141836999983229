import React from 'react';
import { RowData } from '@tanstack/react-table';
import { Table as BTable } from 'react-bootstrap';
import { TablePagination } from './pagination/pagination';
import { TableHeader } from './header';
import { TableBody } from './body/body';
import styles from './table.module.scss';
import styled from 'styled-components';
import { Pagination, TableContext } from './context';
import { useTable, UseTableOptions } from './use-table';

export const StripedTable = styled(BTable)`
  th {
    border: 1px solid #f3f3f3;
    text-align: center;
    background-color: #d3d3d3;
  }

  td {
    border: 1px solid #f3f3f3;
  }

  tr:nth-of-type(even) {
    td {
      background-color: #e3e3e3;
    }
  }
`;

type Props<D extends RowData> = UseTableOptions<D> & {
  pagination?: Pagination;
  loading?: boolean;
  expandable?: boolean;
  emptyText?: string;
};

export const Table = <D extends RowData>(props: Props<D>) => {
  const { pagination, loading, emptyText, ...rest } = props;
  const table = useTable<D>(rest);

  return (
    <TableContext.Provider
      value={{ ...table, loading, pagination, emptyText }}
    >
      <div className={styles.container}>
        <BTable>
          <TableHeader />
          <TableBody />
        </BTable>
        {pagination && <TablePagination />}
      </div>
    </TableContext.Provider>
  );
};
